import {createI18n} from 'vue-i18n/dist/vue-i18n.mjs';
import en from '@/locales/en.json';

function customRuleForRU(n) {
    return (n > 9 && /1$/.test(n)) ? 3 : (n % 100 > 4 && n % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(n % 10 < 5) ? n % 10 : 5]
}

export default createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {en},
    ssrAttribute: 'renderer-ready',
    pluralizationRules: {
        ru: customRuleForRU
    }
})
