<template>
    <div></div>
</template>

<script>
export default {
    name: "site_theme",
    mounted() {
        document.addEventListener('keypress', (e) => {
            if (e.ctrlKey && e.keyCode === 17) this.toggleTheme(null)
        })
    },
    methods: {
        toggleTheme(theme) {
            this.$store.commit("CHANGE_SITE_THEME_PREFERS", {animation: true, theme: theme});
        },
    },
}
</script>

<style scoped lang="scss">

</style>