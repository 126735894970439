import {createApp} from 'vue';
import router from '@/router';
import store from '@/store';
import axios from '@/plugins/axios';
import VueAxios from 'vue-axios';
import i18n from '@/plugins/i18n.js';
import App from '@/App.vue';
import platform from '@/assets/js/platform';
import '@/plugins/registerServiceWorker';
import Cookies from 'js-cookie';
import SUPPORTED_LOCALES from "../supported_locales.js";
import package_json from '../package.json';
import w_alert from '@/components/by_wola/w_alert.vue';
import w_button from '@/components/by_wola/w_button.vue';
import w_input from '@/components/by_wola/w_input.vue';
import w_textarea from '@/components/by_wola/w_textarea.vue';
import w_img from '@/components/by_wola/w_img.vue';
import w_popup from '@/components/by_wola/w_popup.vue';
import w_svg_icon from '@/components/by_wola/w_svg_icon.vue';
import w_img_icon from '@/components/by_wola/w_img_icon.vue';
import w_loading from '@/components/by_wola/w_loading.vue';
import w_loading_min from '@/components/by_wola/w_loading_min.vue';
import w_loading_list from '@/components/by_wola/w_loading_list.vue';
import w_flag from '@/components/by_wola/w_flag.vue';
import w_languages_select from '@/components/by_wola/w_languages_select.vue';
import w_languages_multiselect from '@/components/by_wola/w_languages_multiselect.vue';
import vClickOutside from "click-outside-vue3";

const app = createApp(App);

app.component('wAlert', w_alert);
app.component('wButton', w_button);
app.component('wInput', w_input);
app.component('wTextarea', w_textarea);
app.component('wImg', w_img);
app.component('wPopup', w_popup);
app.component('wSvgIcon', w_svg_icon);
app.component('wImgIcon', w_img_icon);
app.component('wLoading', w_loading);
app.component('wLoadingMin', w_loading_min);
app.component('wLoadingList', w_loading_list);
app.component('wFlag', w_flag);
app.component('wLanguagesSelect', w_languages_select);
app.component('wLanguagesMultiselect', w_languages_multiselect);
app.directive('clickOutside', vClickOutside.directive);


app.config.globalProperties.$axios = axios;
store.$axios = axios;

const BRIEFCASE_VERSION = package_json.version;

store.dispatch("UPDATE_BRIEFCASE_VERSION", BRIEFCASE_VERSION)

let api_url, api_url_locals, api_url_chat, stripe_key;

if (store.state.other.development_host && !localStorage['prod_api']) {
    api_url = 'https://wola.dev/api/v1';
    api_url_locals = 'https://locals.wola.dev/api/v1';
    api_url_chat = '';
    stripe_key = 'pk_test_51PGTm9FhyFYIBQpEoxNnEoVCkx6xmcPktd8GzZWTrs9hIySjbe8DZtGD1a2Jn4VgEo0wwYJzvi62jBeIIMN3396s00QSczzEDb';
} else {
    api_url = 'https://wola.io/api/v1';
    api_url_locals = 'https://localit.io/api/v1';
    api_url_chat = 'https://chat.wola.io/api/v1';
    stripe_key = 'pk_live_51O4ddbCY06bZZxtXLZ8GF2UqllJQbyNqExh8kCGlTVfYKdL591PJH8EKJjz3oWLYpJmm59XXaDcJMPnMdCp6bX3600YhR1NqMJ';
}

app.config.globalProperties.$web_version = BRIEFCASE_VERSION;
app.config.globalProperties.$api_url = api_url;
app.config.globalProperties.$api_url_locals = api_url_locals;
app.config.globalProperties.$api_url_chat = api_url_chat;
app.config.globalProperties.$stripe_key = stripe_key;
app.config.globalProperties.$platform = platform.platform;
app.config.globalProperties.$random = platform.random;
app.config.globalProperties.$is_robot = platform.is_robot;
app.config.globalProperties.$is_webview = platform.is_webview;
app.config.globalProperties.$chat_images = new Map();
app.config.globalProperties.$chat_voices = new Map();
app.config.globalProperties.$chat_ai_uuid = 'f6dcc981-fa8c-4eb4-b800-bd6784564185';
app.config.globalProperties.$page_transition = {
    name: 'bounce',
    mode: '',
    setParams(n, m) {
        this.name = n;
        this.mode = m;
    }
};
store.$api_url = api_url;
store.$api_url_locals = api_url_locals;
store.$api_url_chat = api_url_chat;

let user_locale = Cookies.get('cookie_localize')
if (user_locale) store.commit("SAVE_USER_LOCALE", SUPPORTED_LOCALES.find(l => l.code === user_locale))

app
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueAxios, axios)
    .mount('#app');
