import axios from "axios";

const getDefaultState = () => {
    return {
        orders_list: [],
        orders_list_limit: 20,
        orders_list_all_loaded: false,
        orders_key: 1,
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async GET_ORDERS_LIST({state, commit}, params) {
            params['limit'] = state.orders_list_limit;

            await axios
                .get(this.$api_url_locals + "/order/list", {params})
                .then(async resp => {
                    const list = resp.data.data.orders;

                    if (list.length < state.orders_list_limit) await commit("SAVE_ORDERS_LIST_ALL_LOADED", true);
                    commit("SAVE_ORDERS_LIST", {list, download: !!params.last_order_number});
                })
        },
        GET_ORDER_ESTIMATES({commit}, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get(this.$api_url_locals + "/order/estimates", {params})
                    .then(response => resolve(response.data.data.estimates))
                    .catch(err => reject(err))
                    .finally(() => commit)
            })
        },
        CREATE_NEW_ORDER({commit}, params) {
            return new Promise((resolve, reject) => {
                axios
                    .post(this.$api_url_locals + "/order", params)
                    .then(resp => {
                        commit("ADD_NEW_ORDER_IN_LIST", resp.data.data.order);
                        commit("UPDATE_KEYS");
                        resolve(resp.data.data.order)
                    })
                    .catch(err => reject(err))
            })
        },
        async CANCEL_ORDER({commit}, order_number) {
            await axios
                .delete(this.$api_url_locals + "/order/" + order_number)
                .then(() => {
                    commit("REMOVE_ORDER_FROM_LIST", order_number);
                    commit("UPDATE_KEYS");
                })
        },
    },
    mutations: {
        SAVE_ORDERS_LIST: (state, payload) => {
            if (!payload) state.orders_list = [];
            else if (payload.download) state.orders_list = state.orders_list.concat(payload.list);
            else state.orders_list = payload.list;
        },
        SAVE_ORDERS_LIST_ALL_LOADED: (state, boolean) => state.orders_list_all_loaded = boolean,
        ADD_NEW_ORDER_IN_LIST: (state, order) => state.orders_list.unshift(order),
        REMOVE_ORDER_FROM_LIST: (state, order_number) => state.orders_list = state.orders_list.filter(o => o.order_number !== order_number),
        UPDATE_ORDERS_KEY: (state) => state.orders_key += 1,
        CLEAR_ORDERS: (state) => Object.assign(state, getDefaultState())
    },
}
