import axios from "axios";

const getDefaultState = () => {
    return {
        user_info: {},
        user_languages: [],
        native_lang: null,
        current_rate: null,
        deleted_account_loading: false,
        rates_list: []
    }
}

let loading_user = false,
    profile_update = true

export default {
    state: () => getDefaultState(),
    getters: {
        USER: state => state.user_info,
        CURRENT_SUBSCRIPTION_PLAN: state => {
            if (!state.current_rate) return {}
            else return state.current_rate.subscription_plan
        },
        KEYS_LIMIT_EXCEEDED: (state, getters) => {
            if (state.current_rate) {
                return state.current_rate.stats.keys_count > getters.CURRENT_SUBSCRIPTION_PLAN.keys_limit
            } else return true
        },
        TEAMMATES_LIMIT_EXCEEDED: (state, getters) => {
            if (state.current_rate) {
                return state.current_rate.stats.teammates_count > getters.CURRENT_SUBSCRIPTION_PLAN.teammates_limit
            } else return true
        }
    },
    actions: {
        LOAD_USER({dispatch}) {
            return new Promise((resolve) => {
                if (!loading_user && profile_update) {
                    loading_user = true
                    profile_update = false

                    let a = 0, stop = function (count) {
                        if (count === 2) {
                            loading_user = false
                            resolve()
                        }
                    }

                    dispatch("LOAD_USER_INFO").then(() => stop(++a))
                    dispatch("LOAD_USER_LANG").finally(() => stop(++a))
                } else resolve()
            })
        },
        async LOAD_USER_INFO({commit}) {
            await axios
                .get(this.$api_url + "/user/profile")
                .then(user_info => {
                    commit("SAVE_USER", user_info.data.data)
                });
        },
        async LOAD_USER_LANG({commit}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(this.$api_url + "/user/languages")
                    .then(response => {
                        commit("SAVE_USER_LANG", response.data.data)
                        resolve()
                    })
                    .catch((err) => reject(err))
            })
        },
        async DELETE_USER_AVATAR({commit}) {
            await axios
                .delete(this.$api_url + "/user/avatar")
                .then(() => commit("DELETE_AVATAR"))
        },
        CHANGE_USER_NICKNAME({commit}, nickname) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(this.$api_url + "/user/profile/nickname", {nickname})
                    .then(() => {
                        commit("SAVE_USER_NICKNAME", nickname)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        async SET_USER_NATIVE_LANG({state, commit}, lang) {
            let native_lang_id = state.user_languages.find(l => l.level === 'NATIVE')?.id;

            if (!native_lang_id) return;

            await axios
                .put(this.$api_url + "/user/languages/" + native_lang_id, {
                    "lang_code": lang.code,
                    "level": "NATIVE"
                })
                .then(resp => {
                    commit("SAVE_USER_LANG", resp.data.data)
                    commit("CLEAR_TASKS")
                    commit("CLEAR_MODERATION_TASKS")
                    commit("SAVE_LAST_LANGUAGES", lang)
                })
        },
        EDIT_USER_PROFILE({commit}, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post(this.$api_url + "/user/profile", data)
                    .then(response => {
                        commit("SAVE_USER", response.data.data)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        async UPDATE_PASSWORD({commit}, data) {
            await axios.post(`${this.$api_url}/user/password/update`, data)
                .then(response => {
                    const token = response.data['access_token'], token_type = response.data['token_type'];
                    commit('SAVE_TOKEN', token);
                    commit('SAVE_TOKEN_TYPE', token_type);
                    axios.defaults.headers.common['authorization'] = token_type + ' ' + token;
                })
        },
        async EMAIL_VERIFICATION_POST({commit}) {
            await axios
                .post(`${this.$api_url}/email-verification/resend`, {source: 'localit'})
                .then(() => commit("SAVE_USER_VERIFIED_POPUP", false))
        },
        async EMAIL_VERIFICATION_GET({commit}, data) {
            commit
            return new Promise((resolve, reject) => {
                axios.get(`${this.$api_url}/email-verification/verify/${data.id}/${data.hash}`)
                    .then(response => resolve(response.data.data))
                    .catch(error => reject(error))
            })
        },
        CHECK_USER_EMAIL_VERIFICATION({getters, commit}, need_to_request) {
            function sendEmailChecking() {
                if (need_to_request) {
                    axios
                        .post(this.$api_url + '/user/email/is-verified')
                        .then(resp => {
                            let is_verified = resp.data.data.is_verified;
                            commit("SAVE_USER_VERIFIED", is_verified);
                            localStorage.removeItem('verified_popup');
                            if (!is_verified) setTimeout(() => commit("SAVE_USER_VERIFIED_POPUP", true), 800);
                        })
                } else {
                    localStorage.removeItem('verified_popup');
                    setTimeout(() => commit("SAVE_USER_VERIFIED_POPUP", true), 800);
                }
            }

            if (getters.isLoggedIn) {
                if (getters.USER.is_email_verified) {
                    localStorage.removeItem('verified_popup');
                } else {
                    let ls_date = localStorage.getItem('verified_popup');

                    if (ls_date) {
                        if (Math.floor((Date.now() - ls_date) / 1000) > 86400) sendEmailChecking()
                    } else sendEmailChecking()
                }
            }
        },
        async LOAD_RATES_LIST({commit}) {
            await axios
                .get(this.$api_url_locals + '/subscription/list')
                .then(resp => {
                    let rates = resp.data.data.subscriptions;
                    rates.forEach(r => {
                        r.price_yearly = Number((r.price_yearly / 100).toFixed(0))
                        r.price_monthly = Number((r.price_monthly / 100).toFixed(0))
                        r.price_yearly_ru = Number((r.price_yearly_ru / 100).toFixed(0))
                        r.price_monthly_ru = Number((r.price_monthly_ru / 100).toFixed(0))
                    });
                    commit("SAVE_RATES_LIST", rates);
                })
                .catch(err => {
                    throw new Error(err)
                })
        },
        async GET_CURRENT_RATE({commit}, params) {
            await axios
                .get(this.$api_url_locals + '/subscription', {params})
                .then(resp => {
                    let s = resp.data.data.team_subscription;
                    s.price_yearly = Number((s.price_yearly / 100).toFixed(0));
                    s.price_monthly = Number((s.price_monthly / 100).toFixed(0));
                    s.price_yearly_ru = Number((s.price_yearly_ru / 100).toFixed(0));
                    s.price_monthly_ru = Number((s.price_monthly_ru / 100).toFixed(0));
                    commit("SAVE_CURRENT_RATE", s);
                })
                .catch(err => {
                    throw new Error(err)
                })
        },
        async UNSUBSCRIBE_RATE({commit}, params) {
            await axios
                .patch(this.$api_url_locals + '/subscription/cancel', params)
                .then(() => commit("UPDATE_ORDERS_KEY"))
                .catch(err => {
                    throw new Error(err)
                })
        },
        async UPGRADE_RATE({commit}, params) {
            await axios
                .patch(this.$api_url_locals + '/subscription/upgrade', params)
                .then(() => {
                    commit
                })
                .catch(err => {
                    throw new Error(err)
                })
        }
    },
    mutations: {
        SAVE_USER: (state, user) => state.user_info = user,
        SAVE_USER_NICKNAME: (state, nickname) => state.user_info.nickname = nickname,
        SAVE_USER_VERIFIED: (state, boolean) => state.user_info.is_email_verified = boolean,
        SAVE_USER_LANG: (state, user_languages) => {
            let order = ['NATIVE', 'EXPERT', 'ADVANCED', 'BASE', 'ELEMENTARY']
            state.user_languages = user_languages.sort((a, b) => order.indexOf(a.level) - order.indexOf(b.level))

            let native_lang = user_languages.find(lang => lang.level === "NATIVE")
            if (native_lang) state.native_lang = native_lang.lang.code;
        },
        DELETE_AVATAR: (state) => {
            state.user_info['avatar'] = '';
            state.user_info['mini_avatar'] = '';
            state.user_info['medium_avatar'] = '';
        },
        CHANGE_DELETED_ACCOUNT_LOADING: (state, boolean) => state.deleted_account_loading = boolean,
        SAVE_RATES_LIST: (state, list) => state.rates_list = list,
        SAVE_CURRENT_RATE: (state, rate) => state.current_rate = rate,
        CLEAR_USER: (state) => {
            Object.assign(state, getDefaultState());
            loading_user = false;
            profile_update = true;
        }
    }
}
