const getDefaultState = () => {
    return {
        chat_unsent_messages: [],
        chat_not_publish_messages: {},
        deleted_key_ids: [],
        projects_languages_filter: {},
        project_search_filters: {},
        hide_placeholder_notice: false,
        list_placeholder_code_mode: false,
        projects_download_options: {},
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        DOWNLOAD_OPTIONS: (state, getters) => state.projects_download_options[getters.SELECTED_PROJECT.uuid] || {}
    },
    actions: {
        SAVE_PROJECT_LANGUAGES_FILTER({state, getters}, languages_ids) {
            state.projects_languages_filter[getters.SELECTED_PROJECT.uuid] = languages_ids
        },
        SAVE_PROJECT_SEARCH_FILTERS({state, getters}, filters) {
            if (!filters) state.project_search_filters = {}
            else state.project_search_filters = {...filters, project_uuid: getters.SELECTED_PROJECT.uuid}
        },
        SAVE_DOWNLOAD_OPTIONS({state, getters}, payload) {
            let p_uuid = getters.SELECTED_PROJECT.uuid;
            if (!(p_uuid in state.projects_download_options)) state.projects_download_options[p_uuid] = {};
            state.projects_download_options[p_uuid][payload[0]] = payload[1];
        }
    },
    mutations: {
        UPDATE_CHAT_NOT_PUBLISH_MESSAGES: (state, data) => {
            if (!state.chat_not_publish_messages[data.uuid]) state.chat_not_publish_messages[data.uuid] = {}
            if ("quote" in data) state.chat_not_publish_messages[data.uuid]["quote"] = data.quote
            if ("msg" in data) state.chat_not_publish_messages[data.uuid]["message"] = data.msg
            if ("correction" in data) state.chat_not_publish_messages[data.uuid]["correction"] = data.correction
        },
        SAVE_DELETED_KEY_IDS(state, payload) {
            if (payload.delete) {
                state.deleted_key_ids = state.deleted_key_ids.filter(id => id !== payload.key_id);
            } else if (payload.add) {
                state.deleted_key_ids.push(payload.key_id);
                state.deleted_key_ids = Array.from(new Set(state.deleted_key_ids));
            }
        },
        SAVE_UNSENT_MESSAGE: (state, data) => {
            if (!data.type) {
                let index = state.chat_unsent_messages.findIndex(m => m.id === data.message.client_message_id);
                if (index >= 0) state.chat_unsent_messages.splice(index, 1);
            } else {
                let index = state.chat_unsent_messages.findIndex(m => m.id === data.message.id);

                if (data.type === 'save') {
                    if (index < 0) state.chat_unsent_messages.push(data.message);
                } else if (index >= 0) {
                    state.chat_unsent_messages.splice(index, 1);
                }
            }
        },
        TOGGLE_LIST_PLACEHOLDER_CODE_MODE: (state) => state.list_placeholder_code_mode = !state.list_placeholder_code_mode,
        CHANGE_HIDE_PLACEHOLDER_NOTICE: (state) => state.hide_placeholder_notice = true,
        DELETE_PROJECT_DOWNLOAD_OPTIONS: (state, uuid) => delete state.projects_download_options[uuid],
        CLEAR_CACHE: (state) => Object.assign(state, getDefaultState())
    },
}
