import axios from "axios";
import bus from "@/assets/js/helper.js";

const getDefaultState = () => {
    return {
        projects_list: [],
        selected_project_id: null,
        deleted_project_uuid: null,
        project_file_accepted_formats: '.xml, .strings, .json, .po, .pot',
        project_update: true,
        translation_mode_settings: {}
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        SELECTED_PROJECT: state => state.projects_list.find(p => p.uuid === state.selected_project_id) || {},
        SELECTED_PROJECT_LANGUAGES: (state, getters) => {
            if (getters.SELECTED_PROJECT.project_languages) {
                return getters.SELECTED_PROJECT.project_languages
                    .map(l => {
                        return Object.assign(bus.getLanguage(l.language_id), {
                            project_language_id: l.id,
                            is_main: l.is_main,
                            plural_forms: l.plural_forms,
                            translated_keys_percentage: l.translated_keys_percentage
                        })
                    })
            } else return []
        },
        SELECTED_PROJECT_TRANSLATION_LANGUAGES: (state, getters) => {
            return getters.SELECTED_PROJECT_LANGUAGES.filter(l => !l.is_main);
        },
        SELECTED_PROJECT_MAIN_LANGUAGE: (state, getters) => {
            return getters.SELECTED_PROJECT_LANGUAGES.find(l => l.is_main);
        },
        TRANSLATION_MODE_SETTINGS: (state, getters) => {
            if (!getters.SELECTED_PROJECT.uuid) return {}
            else return state.translation_mode_settings[getters.SELECTED_PROJECT.uuid] || {}
        }
    },
    actions: {
        GET_PROJECTS_LIST({state, commit, getters, dispatch, rootState}, search) {
            if (rootState.loadings.loading_projects_list) return;

            return new Promise((resolve, reject) => {
                if (getters.SELECTED_TEAM.uuid) {
                    if (!state.projects_list.length) commit("CHANGE_LOADING_PROJECTS_LIST", true);

                    let URL = this.$api_url_locals + "/project/list?team_uuid=" + getters.SELECTED_TEAM.uuid;

                    if (search?.name) URL += `&project_name=${search.name}`

                    axios
                        .get(URL)
                        .then(response => {
                            if (search) resolve(response.data.data.projects);
                            else {
                                commit("SAVE_PROJECTS_LIST", response.data.data.projects)
                                dispatch("GET_PROJECTS_INFO");
                                resolve(response.data.data.projects);
                            }
                        })
                        .catch(err => reject(err))
                        .finally(() => commit("CHANGE_LOADING_PROJECTS_LIST", false))
                } else reject('before select team')
            })
        },
        GET_PROJECT({commit}, uuid) {
            return new Promise((resolve, reject) => {
                axios
                    .get(this.$api_url_locals + "/project/" + uuid)
                    .then(response => resolve(response.data.data.project))
                    .catch(err => reject(err))
                    .finally(() => commit)
            })
        },
        GET_PROJECTS_INFO({state, getters, dispatch, commit, rootState}, project_uuid) {
            if (state.projects_list.length) {
                commit("CHANGE_LOADING_PROJECTS_INFO", true);

                let URL = this.$api_url_locals + "/project/keys_info?team_uuid=" + getters.SELECTED_TEAM.uuid;

                if (project_uuid) URL += `&projects_uuids[]=${project_uuid}`
                else {
                    for (let project of state.projects_list) URL += `&projects_uuids[]=${project.uuid}`
                }

                axios
                    .get(URL)
                    .then(response => {
                        commit("SAVE_PROJECTS_INFO", response.data.data.keys_info)
                        state.project_update = false;
                    })
                    .catch(() => {
                        if (rootState.other.development_host) {
                            dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'})
                        }
                    })
                    .finally(() => {
                        commit("CHANGE_LOADING_PROJECTS_INFO", false)
                    })
            }
        },
        async CREATE_NEW_PROJECT({commit, getters}, payload) {
            return new Promise((resolve, reject) => {
                let fData = new FormData();
                fData.append('team_uuid', getters.SELECTED_TEAM.uuid);
                if (payload.name) fData.append('name', payload.name);
                if (payload.main_language_id) fData.append('main_language_id', payload.main_language_id);
                if (payload.avatar) fData.append('avatar', payload.avatar);
                if (payload.language_ids) {
                    for (let i = 0; i < payload.language_ids.length; i++) {
                        fData.append('language_ids[]', payload.language_ids[i]);
                    }
                }

                axios
                    .post(this.$api_url_locals + "/project", fData)
                    .then(response => {
                        commit("SAVE_NEW_PROJECT", response.data.data.project)
                        resolve(response.data.data.project)
                    })
                    .catch((e) => reject(e))
            })
        },
        async CHANGE_PROJECT({commit}, payload) {
            let fData = new FormData();
            if (payload.form_data.name) fData.append('name', payload.form_data.name);
            if (payload.form_data.avatar) fData.append('avatar', payload.form_data.avatar);
            if (payload.form_data.is_avatar_deletion) fData.append('is_avatar_deletion', payload.form_data.is_avatar_deletion);
            if ("manager" in payload.form_data) fData.append('manager_uuid', payload.form_data.manager?.id || "");

            await axios
                .put(this.$api_url_locals + "/project/" + payload.id, fData)
                .then((resp) => {
                    let project = resp.data.data.project;
                    if ("manager" in payload.form_data) {
                        if (payload.form_data.manager) {
                            project.manager = {
                                avatar: payload.form_data.manager.image_url,
                                mini_avatar: payload.form_data.manager.image_url,
                                nickname: payload.form_data.manager.name,
                                uuid: payload.form_data.manager.id,
                            };
                        } else project.manager = null;
                    }
                    commit("UPDATE_PROJECT_IN_LIST", project);
                })
                .catch((err) => {
                    throw new Error(err)
                })
        },
        async CHANGE_PROJECT_LANGUAGES({state, getters, dispatch, commit, rootState}, payload) {
            const tl = getters.SELECTED_PROJECT_TRANSLATION_LANGUAGES.map(l => l.id);
            const new_langs = payload.language_ids.filter(i => !tl.includes(i));

            await axios
                .put(this.$api_url_locals + "/project/language", payload)
                .then((resp) => {
                    let project = state.projects_list.find(p => p.uuid === payload.project_uuid);

                    if (project) {
                        project.project_languages = resp.data.data.project_languages;
                        commit("UPDATE_PROJECT_IN_LIST", project);
                        let filter = rootState.cache.projects_languages_filter[getters.SELECTED_PROJECT.uuid];
                        if (filter?.length && new_langs.length) {
                            const new_langs_valid = project.project_languages.filter(l => new_langs.includes(l.language_id)).map(l => l.id);
                            const new_a = [...new_langs_valid, ...filter];
                            const a = project.project_languages.map(pl => pl.id).filter(id => new_a.includes(id));
                            dispatch("SAVE_PROJECT_LANGUAGES_FILTER", a);
                        }
                        commit("UPDATE_KEYS");
                    }
                })
                .catch((err) => {
                    throw new Error(err)
                })
        },
        async DELETE_PROJECT({commit, state, getters}, uuid) {
            let id = uuid || state.deleted_project_uuid;

            await axios
                .delete(this.$api_url_locals + "/project", {
                    data: {
                        "project_uuid": id,
                        "team_uuid": getters.SELECTED_TEAM.uuid
                    }
                })
                .then(() => {
                    commit("DELETE_PROJECT_DOWNLOAD_OPTIONS", id);
                    commit("DELETE_PROJECT_FROM_LIST", id);
                })
                .catch((err) => {
                    throw new Error(err)
                })
        }
    },
    mutations: {
        SAVE_PROJECTS_LIST(state, list) {
            state.projects_list = list
        },
        SAVE_PROJECTS_INFO(state, info) {
            info.forEach(i => {
                let project = state.projects_list.find(p => p.uuid === i.project_uuid);
                if (project) {
                    project['keys_count'] = i.keys_count;

                    if (i.project_languages_data) {
                        i.project_languages_data.forEach(l => {
                            let language = project.project_languages.find(pl => pl.id === l.project_language_id);
                            if (language) language['translated_keys_percentage'] = l.translated_keys_percentage;
                        })
                    }
                }
            })
        },
        SAVE_NEW_PROJECT(state, project) {
            state.projects_list.unshift(project)
        },
        UPDATE_PROJECT_INFO(state) {
            state.project_update = true;
        },
        DELETE_KEY_FROM_PROJECT(state) {
            let project = state.projects_list.find(p => p.uuid === state.selected_project_id)
            if (project) project.keys_count -= 1
        },
        SAVE_SELECTED_PROJECT_ID(state, id) {
            state.selected_project_id = id ? id : null
        },
        DELETE_PROJECT_FROM_LIST(state, uuid) {
            state.projects_list = state.projects_list.filter(p => p.uuid !== uuid)
        },
        SAVE_DELETED_PROJECT_UUID(state, uuid) {
            state.deleted_project_uuid = uuid
        },
        SAVE_SELECTED_PROJECT_EMPTY_STATUS(state, boolean) {
            let project = state.projects_list.find(p => p.uuid === state.selected_project_id)
            if (project) project.is_empty = boolean
        },
        UPDATE_PROJECT_IN_LIST(state, project) {
            let pr = state.projects_list.find(p => p.uuid === project.uuid);
            if (pr) Object.assign(pr, project);
        },
        SAVE_TRANSLATION_MODE_SETTINGS(state, payload) {
            if (payload.settings) state.translation_mode_settings[payload.project_uuid] = payload.settings
            else delete state.translation_mode_settings[payload.project_uuid]
        },
        CLEAR_PROJECTS(state) {
            Object.assign(state, getDefaultState())
        }
    }
}
