import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';
import SUPPORTED_LOCALES from "@/../supported_locales.js";
import bus from "@/assets/js/helper.js";

const MobileDummy = () => import(/* webpackChunkName: "mobile_dummy_page" */ '../views/MobileDummy.vue')

const AuthView = () => import(/* webpackChunkName: "auth" */ "../views/auth/AuthView.vue");
const ForkPage = () => import(/* webpackChunkName: "auth" */ "../views/auth/ForkPage.vue");
const Login = () => import(/* webpackChunkName: "auth" */ "../views/auth/login/Login.vue");
const SignUp = () => import(/* webpackChunkName: "auth" */ "../views/auth/registration/SignUp.vue");
const ResetPassword = () => import(/* webpackChunkName: "reset_password" */'../views/auth/ResetPassword.vue');

const AboutPage = () => import(/* webpackChunkName: "about_page" */'../views/AboutPage.vue');

const NoAccessPage = () => import(/* webpackChunkName: "not_found" */ "../views/NoAccessPage.vue");
const NoPage404 = () => import(/* webpackChunkName: "not_found" */ "../views/NoPage404.vue");
const TeamPage = () => import(/* webpackChunkName: "team" */ '../views/team/TeamPage.vue');
const TeamProjectsPage = () => import(/* webpackChunkName: "team" */ '../views/team/TeamProjectsPage.vue');
const TeamCrewPage = () => import(/* webpackChunkName: "team" */ '../views/team/TeamCrewPage.vue');
const TeamSupportPage = () => import(/* webpackChunkName: "team" */ '../views/team/TeamSupportPage.vue');
const TeamRatesPage = () => {
    if (store.getters.MOBILE) return MobileDummy();
    else return import(/* webpackChunkName: "team" */ '../views/team/TeamRatesPage.vue');
}
// const TeamSettingsPage = () => import(/* webpackChunkName: "team" */ '../views/team/TeamSettingsPage.vue');
const CreateNewTeamPage = () => import(/* webpackChunkName: "addition" */ '../views/team/CreateNewTeamPage.vue');
const CreateNewProjectPage = () => {
    if (store.getters.MOBILE) return MobileDummy();
    else return import(/* webpackChunkName: "addition" */ '../views/project/CreateNewProjectPage.vue');
}
const ApproveInviteToTeamPage = () => import(/* webpackChunkName: "addition" */ '../views/team/ApproveInviteToTeamPage.vue');
const CreateNewInvitation = () => import(/* webpackChunkName: "addition" */ '../views/team/CreateNewInvitation.vue');

const ProjectPage = () => import(/* webpackChunkName: "project_page" */ '../views/project/ProjectPage.vue');
const ProjectKeysList = () => import(/* webpackChunkName: "project_page" */ '@/components/elements/projects/projectKeysList.vue');
const ProjectTranslationMode = () => import(/* webpackChunkName: "project_page" */ '@/components/elements/projects/projectTranslationMode.vue');
const ProjectDownloadMode = () => {
    if (store.getters.MOBILE) return MobileDummy();
    else return import(/* webpackChunkName: "project_page" */ '@/components/elements/projects/projectDownloadMode.vue');
}
const ProjectUploadMode = () => {
    if (store.getters.MOBILE) return MobileDummy();
    else return import(/* webpackChunkName: "project_page" */ '@/components/elements/projects/projectUploadMode.vue');
}
const ProjectOrders = () => {
    if (store.getters.MOBILE) return MobileDummy();
    else return import(/* webpackChunkName: "project_page" */ '@/components/elements/projects/projectOrders.vue');
}
const ProjectOrderTranslation = () => {
    if (store.getters.MOBILE) return MobileDummy();
    else return import(/* webpackChunkName: "project_page" */ '@/components/elements/projects/projectOrderTranslation.vue');
}
const ProjectSettingsPage = () => import(/* webpackChunkName: "project_settings_page" */ '../views/project/ProjectSettingsPage.vue');

const ProfileSettingsPage = () => {
    if (store.getters.MOBILE) return MobileDummy();
    else return import(/* webpackChunkName: "profile_settings_page" */ '../views/profile/ProfileSettingsPage.vue');
}

const AdditionKeyPage = () => {
    if (store.getters.MOBILE) return MobileDummy();
    else return import(/* webpackChunkName: "addition_key_page" */ '../views/project/AdditionKeyPage.vue');
}

const DetailKeyPage = () => import(/* webpackChunkName: "detail_key_page" */ '../views/project/DetailKeyPage.vue');
const PrivacyPage = () => import(/* webpackChunkName: "privacy_page" */ '../views/PrivacyPage.vue');
const TermsPage = () => import(/* webpackChunkName: "terms_page" */ '../views/TermsPage.vue');
const PublicOfferPage = () => import(/* webpackChunkName: "public_offer_page" */ '../views/PublicOfferPage.vue');

const LoadingPage = () => import(/* webpackChunkName: "loading_page" */ '../views/LoadingPage.vue');

const FinishPage = () => import(/* webpackChunkName: "loading_page" */ '../views/FinishPage.vue');

const PaymentPage = () => {
    if (store.getters.MOBILE) return MobileDummy();
    else return import(/* webpackChunkName: "payment_page" */ '../views/PaymentPage.vue');
}

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isLoggedIn) {
        next();
        return
    }
    next('/auth')
};
const ifUnauthenticated = (to, from, next) => {
    if (!store.getters.isLoggedIn) {
        next();
        return
    }
    next('/')
};
const ifAuthenticatedFromSocial = (to, from, next) => {
    if (store.getters.isLoggedIn && (store.state.profile.user_info.need_to_set_nickname || !store.state.profile.user_info.nickname || !store.state.profile.native_lang)) {
        next();
    }
};
const ifAdmin = (to, from, next) => {
    if (store.getters.IS_ADMIN) {
        next();
        return;
    }
    next(`/team/${store.getters.SELECTED_TEAM.uuid}`);
};
const ifNotDeveloper = (to, from, next) => {
    if (!store.getters.IS_DEVELOPER) next();
};
const ifNotTranslator = (to, from, next) => {
    if (!store.getters.IS_TRANSLATOR.status) next();
};

function getLocaleRegex() {
    let reg = '';

    SUPPORTED_LOCALES.forEach((locale, index) => {
        reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`;
    });
    return `(${reg})`;
}

const routes = [
    {
        path: `/:locale${getLocaleRegex()}?`,
        children: [
            {
                path: '',
                name: 'PromoPage',
                component: AboutPage,
                meta: {
                    title: 'about_meta_title',
                    description: 'about_meta_desc'
                }
            },
            {
                path: 'auth',
                component: AuthView,
                children: [
                    {
                        path: '',
                        name: 'Auth',
                        component: ForkPage,
                        beforeEnter: ifUnauthenticated,
                    },
                    {
                        path: 'login/:type',
                        name: 'Login',
                        component: Login,
                        beforeEnter: ifUnauthenticated,
                    },
                    {
                        path: 'sign_up',
                        redirect: '/auth/sign_up/email'
                    },
                    {
                        path: 'sign_up/:step',
                        name: 'SignUp',
                        component: SignUp,
                        beforeEnter: ifUnauthenticated,
                    },
                    {
                        path: 'complete_profile/:step',
                        name: 'CompleteSocialProfile',
                        component: SignUp,
                        beforeEnter: ifAuthenticatedFromSocial
                    },
                    {
                        path: 'add_native_lang',
                        name: 'addNativeLang',
                        component: SignUp,
                    },
                ]
            },
            {
                path: 'password-reset',
                query: {token: ''},
                name: 'ResetPassword',
                component: ResetPassword,
            },
            {
                path: 'create_team',
                name: 'CreateNewTeamPage',
                component: CreateNewTeamPage,
                beforeEnter: ifAuthenticated
            },
            {
                path: 'team/:team_uuid',
                name: 'TeamPage',
                component: TeamPage,
                beforeEnter: ifAuthenticated,
                redirect: {name: 'TeamProjectsPage'},
                children: [
                    {
                        path: '',
                        name: 'TeamPageDouble',
                        redirect: {name: 'TeamProjectsPage'}
                    },
                    {
                        path: 'projects',
                        name: 'TeamProjectsPage',
                        component: TeamProjectsPage,
                        meta: {
                            title: 'all_projects'
                        }
                    },
                    {
                        path: 'orders',
                        name: 'TeamOrdersPage',
                        component: ProjectOrders,
                        beforeEnter: ifAdmin
                    },
                    {
                        path: 'new_order',
                        name: 'TeamOrderTranslation',
                        component: ProjectOrderTranslation,
                        beforeEnter: ifAdmin
                    },
                    {
                        path: 'crew',
                        name: 'TeamCrewPage',
                        component: TeamCrewPage,
                    },
                    {
                        path: 'support',
                        name: 'TeamSupportPage',
                        component: TeamSupportPage,
                    },
                    {
                        path: 'settings',
                        name: 'TeamSettingsPage',
                        component: CreateNewTeamPage,
                        props: {
                            edit: true
                        }
                    },
                    {
                        path: 'rates',
                        name: 'TeamRatesPage',
                        component: TeamRatesPage
                    }
                ]
            },
            {
                path: 'team/:team_uuid/crew/new_invitation',
                name: 'CreateNewInvitation',
                component: CreateNewInvitation,
                beforeEnter: ifAuthenticated,
            },
            {
                path: 'team/:team_uuid/crew/edit_member',
                name: 'EditMember',
                component: CreateNewInvitation,
                beforeEnter: ifAuthenticated,
                props: {
                    edit_page: true
                }
            },
            {
                path: 'team/:team_uuid/create_project',
                name: 'CreateNewProjectPage',
                component: CreateNewProjectPage,
                beforeEnter: ifAuthenticated
            },
            {
                path: 'invite-to-team',
                name: 'ApproveInviteToTeamPage',
                query: {
                    'uuid_invitation': ''
                },
                component: ApproveInviteToTeamPage
            },
            {
                path: 'team/:team_uuid/projects/:project_uuid',
                component: ProjectPage,
                beforeEnter: ifAuthenticated,
                children: [
                    {
                        path: '',
                        name: 'ProjectPage',
                        component: ProjectKeysList
                    },
                    {
                        path: 'translation_mode',
                        name: 'ProjectTranslationMode',
                        component: ProjectTranslationMode,
                        beforeEnter: ifNotDeveloper
                    },
                    {
                        path: 'verification_mode',
                        name: 'ProjectVerificationMode',
                        component: ProjectTranslationMode,
                        beforeEnter: ifNotDeveloper,
                        props: {
                            check_mode: true
                        }
                    },
                    {
                        path: 'download',
                        name: 'ProjectDownloadMode',
                        component: ProjectDownloadMode,
                        beforeEnter: ifNotTranslator
                    },
                    {
                        path: 'upload',
                        name: 'ProjectUploadMode',
                        component: ProjectUploadMode,
                        beforeEnter: ifNotTranslator
                    },
                    {
                        path: 'orders',
                        name: 'ProjectOrders',
                        component: ProjectOrders,
                        beforeEnter: ifAdmin
                    },
                    {
                        path: 'order_translation',
                        name: 'ProjectOrderTranslation',
                        component: ProjectOrderTranslation,
                        beforeEnter: ifAdmin
                    },
                    {
                        path: 'add_key',
                        name: 'AdditionKeyPage',
                        component: AdditionKeyPage
                    },
                    {
                        path: 'key/:key_id',
                        name: 'DetailKeyPage',
                        component: DetailKeyPage
                    },
                    {
                        path: 'key/:key_id/edit',
                        name: 'EditionKeyPage',
                        component: AdditionKeyPage,
                        props: {
                            edit_page: true
                        }
                    },
                    {
                        path: 'key',
                        redirect: {name: 'ProjectPage'}
                    },
                    {
                        path: 'settings-:setting_section(general|languages|notifications)',
                        name: 'ProjectSettingsPage',
                        component: ProjectSettingsPage,
                        beforeEnter: ifAdmin
                    },
                ]
            },
            {
                path: 'profile_settings',
                name: 'ProfileSettingsPage',
                component: ProfileSettingsPage,
                beforeEnter: ifAuthenticated
            },
            {
                path: '403',
                name: 'NoAccessPage',
                component: NoAccessPage,
                meta: {
                    title: 'Localit ● ' + '403'
                },
            },
            {
                path: '404',
                name: 'NoPage404',
                component: NoPage404,
                meta: {
                    title: 'Localit ● ' + '404'
                },
            },
            {
                path: 'loading',
                name: 'LoadingPage',
                component: LoadingPage,
                beforeEnter: ifAuthenticated
            },
            {
                path: 'finish_page',
                name: 'FinishPage',
                component: FinishPage
            },
            {
                path: 'payment/order/success',
                name: 'PaymentPageSuccess',
                component: PaymentPage
            },
            {
                path: 'payment/order/failed',
                name: 'PaymentPageFailed',
                component: PaymentPage
            },
            {
                path: 'payment/order',
                name: 'PaymentPage',
                component: PaymentPage
            },
            {
                path: 'payment/rate/success',
                name: 'PaymentRatePageSuccess',
                component: PaymentPage,
                props: {
                    rate_payment: true
                }
            },
            {
                path: 'payment/rate/failed',
                name: 'PaymentRatePageFailed',
                component: PaymentPage,
                props: {
                    rate_payment: true
                }
            },
            {
                path: 'payment/rate',
                name: 'PaymentRatePage',
                component: PaymentPage,
                props: {
                    rate_payment: true
                }
            },
            {
                path: 'payment',
                redirect: 'payment/order'
            },
            {
                path: 'about',
                redirect: {name: 'PromoPage'}
            },
            {
                path: 'privacy',
                name: 'PrivacyPage',
                component: PrivacyPage,
                meta: {
                    title: 'link_privacy_policy',
                    description: 'about_meta_desc'
                }
            },
            {
                path: 'terms',
                name: 'TermsPage',
                component: TermsPage,
                meta: {
                    title: 'link_terms_and_conditions',
                    description: 'about_meta_desc'
                }
            },
            {
                path: 'public_offer',
                name: 'PublicOfferPage',
                component: PublicOfferPage,
                meta: {
                    title: 'link_public_offer',
                    description: 'about_meta_desc'
                }
            },
            {
                path: ':pathMatch(.*)*',
                name: 'not-found',
                redirect: '/loading'
            }
        ],
        meta: {
            title: 'about_meta_title',
            description: 'about_meta_desc'
        },
        beforeEnter(to) {
            if (store.getters.USER_LOCALE.code && (to.params.locale !== store.getters.USER_LOCALE.route_code)) {
                let route = to
                route.params.locale = store.getters.USER_LOCALE.route_code
                router.replace(route)
            }
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        let position;

        if (to.hash) position = {selector: to.hash}
        else if (savedPosition) position = savedPosition
        else position = {left: 0, top: 0}

        to['position'] = position;

        return new Promise((resolve) => resolve(position))
    },
    routes
})

const originalPush = router.push;
const originalReplace = router.replace;

router.push = function push(location) {
    if (typeof location === 'object') {
        if (location.params) location.params['locale'] = store.getters.LOCALE.route_code
        else location['params'] = {'locale': store.getters.LOCALE.route_code}
    }
    return originalPush.call(this, location).catch(err => {
        if (err.name === 'NavigationDuplicated') console.info('Localit: Navigation duplicated')
        else if (err.message.includes('Redirected when going from')) console.info('Localit: Redirected')
        else throw new Error(err)
    });
};
router.replace = function replace(location) {
    if (typeof location === 'object') {
        if (location.params) location.params['locale'] = store.getters.LOCALE.route_code
        else location['params'] = {'locale': store.getters.LOCALE.route_code}
    }
    return originalReplace.call(this, location).catch(err => {
        if (err.name === 'NavigationDuplicated') console.info('Localit: Navigation duplicated')
        else throw new Error(err)
    });
};

router.beforeEach((to) => {
    bus.updateTitles(to)

    let alt_l = document.querySelector('link[rel="alternate"]'),
        alt_dl = document.querySelector('link[rel="alternate"][hreflang="x-default"]');

    alt_l.setAttribute("href", "https://localit.io/" + to.params.locale);
    alt_l.setAttribute("hreflang", to.params.locale || 'en');

    if (to.params.locale === '') {
        if (!alt_dl) {
            let default_link = document.createElement('link');
            default_link.setAttribute("rel", "alternate");
            default_link.setAttribute("href", "https://localit.io/");
            default_link.setAttribute("hreflang", "x-default");
            alt_l.parentNode.insertBefore(default_link, alt_l.nextSibling);
        }
    } else if (alt_dl) alt_dl.parentNode.removeChild(alt_dl)
})

export default router;
