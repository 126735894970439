import store from '@/store';

export default {
    convert(text) {
        let charsArray = {
            'Q': 'Й',
            'W': 'Ц',
            'E': 'У',
            'R': 'К',
            'T': 'Е',
            'Y': 'Н',
            'U': 'Г',
            'I': 'Ш',
            'O': 'Щ',
            'P': 'З',
            '[': 'Х',
            ']': 'Ъ',
            'A': 'Ф',
            'S': 'Ы',
            'D': 'В',
            'F': 'А',
            'G': 'П',
            'H': 'Р',
            'J': 'О',
            'K': 'Л',
            'L': 'Д',
            ';': 'Ж',
            '\'': 'Э',
            'Z': 'Я',
            'X': 'Ч',
            'C': 'С',
            'V': 'М',
            'B': 'И',
            'N': 'Т',
            'M': 'Ь',
            ',': 'Б',
            '.': 'Ю',
            '/': '.',
            'q': 'й',
            'w': 'ц',
            'e': 'у',
            'r': 'к',
            't': 'е',
            'y': 'н',
            'u': 'г',
            'i': 'ш',
            'o': 'щ',
            'p': 'з',
            'a': 'ф',
            's': 'ы',
            'd': 'в',
            'f': 'а',
            'g': 'п',
            'h': 'р',
            'j': 'о',
            'k': 'л',
            'l': 'д',
            'z': 'я',
            'x': 'ч',
            'c': 'с',
            'v': 'м',
            'b': 'и',
            'n': 'т',
            'm': 'ь',
        };

        let newText = '';

        let in_k = ['ru', 'uk'].includes(store.getters.LOCALE.code)

        for (let i = 0; i < text.length; i++) {
            let character = text.charAt(i);
            if (in_k) {
                newText += (character in charsArray) ? charsArray[character] : character;
            } else {
                let char = '';
                for (const prop in charsArray) if (character === charsArray[prop]) char = prop;
                newText += char;
            }
        }

        return newText.length ? newText : text;
    }
}
