import axios from "axios";

const getDefaultState = () => {
    return {
        crew_list: [],
        deleted_member: null,
        edited_member_email: sessionStorage.getItem('edited_member_email') || null
    }
}

let team_loading = false;

export default {
    state: () => getDefaultState(),
    getters: {
        IS_OWNER: (state, getters) => {
            let user_in_crew = state.crew_list.find(m => m.uuid === getters.USER.uuid)
            if (user_in_crew) return user_in_crew.role === 'owner'
            else return false
        },
        IS_ADMIN: (state, getters) => {
            if (getters.IS_OWNER) return true
            else {
                let user_in_crew = state.crew_list.find(m => m.uuid === getters.USER.uuid)
                if (user_in_crew) return user_in_crew.role === 'admin'
                else return false
            }
        },
        IS_DEVELOPER: (state, getters) => {
            let user_in_crew = state.crew_list.find(m => m.uuid === getters.USER.uuid)
            if (user_in_crew) return user_in_crew.role === 'developer'
            else return false
        },
        IS_TRANSLATOR: (state, getters) => {
            let user_in_crew = state.crew_list.find(m => m.uuid === getters.USER.uuid)
            if (user_in_crew) {
                return {
                    status: user_in_crew.role === 'translator',
                    languages: user_in_crew.languages || []
                }
            } else {
                return {status: false, languages: []}
            }
        }
    },
    actions: {
        async GET_CREW_LIST({getters, dispatch, commit, rootState}, payload) {
            if (!team_loading && rootState.teams.selected_team.uuid) {
                team_loading = true;

                const update_type = payload === 'update';
                let URL = this.$api_url_locals + "/team/" + rootState.teams.selected_team.uuid;

                if (!update_type && payload) URL += decodeURI(payload);

                await axios
                    .get(URL)
                    .then(response => {
                        commit("SAVE_CREW_LIST", response.data.data.team.users || []);

                        if (update_type && getters.SELECTED_TEAM.uuid && getters.IS_ADMIN) {
                            dispatch("GET_CURRENT_RATE", {team_uuid: getters.SELECTED_TEAM.uuid});
                        }
                    })
                    .catch(() => {
                        if (update_type) commit("SAVE_CREW_LIST", []);
                    })
                    .finally(() => team_loading = false)
            }
        },
        async CREATE_NEW_INVITATION({dispatch}, payload) {
            await axios
                .post(this.$api_url_locals + "/team/invitation", payload)
                .then(async () => {
                    await dispatch("GET_CREW_LIST")
                })
                .catch((e) => {
                    return Promise.reject(e);
                })
        },
        async DELETE_INVITATION({getters, commit}, email) {
            await axios
                .delete(this.$api_url_locals + "/team/invitation", {
                    data: {
                        "email": email,
                        "team_uuid": getters.SELECTED_TEAM.uuid
                    }
                })
                .then(() => commit("CLEAR_USER_FROM_CREW", email))
        },
        async DELETE_CREW_MEMBER({state, getters, dispatch, commit}) {
            const email = state.deleted_member.email;

            await axios
                .delete(this.$api_url_locals + "/team/role", {
                    data: {
                        "user_uuid": state.deleted_member.uuid,
                        "team_uuid": getters.SELECTED_TEAM.uuid
                    }
                })
                .then(() => {
                    if (state.deleted_member.uuid === getters.USER.uuid) {
                        commit("DELETE_TEAM_FROM_LIST", getters.SELECTED_TEAM.uuid);
                    } else {
                        commit("CLEAR_USER_FROM_CREW", email);
                    }

                    commit("SAVE_DELETED_MEMBER", null);
                })
                .catch(() => {
                    dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                })
        },
        async EDIT_USER_ROLE({state, commit}, payload) {
            await axios
                .patch(this.$api_url_locals + "/team/role", payload)
                .then(() => {
                    let user = state.crew_list.find(u => u.uuid === payload.user_uuid);

                    if (user) {
                        user.role = payload.role;
                        commit("UPDATE_USER_IN_CREW", user);
                    }
                })
        },
        async EDIT_USER_TRANSLATION_LANGUAGES({state, commit}, payload) {
            await axios
                .put(this.$api_url_locals + "/team/role_language_permission", payload)
                .then(() => {
                    let user = state.crew_list.find(u => u.uuid === payload.user_uuid);

                    if (user) {
                        user.languages = payload.languages_ids;
                        commit("UPDATE_USER_IN_CREW", user);
                    }
                })
        },
        async EDIT_USER_PROJECT_PERMISSION({state, commit}, payload) {
            await axios
                .put(this.$api_url_locals + "/team/project_permission", payload)
                .then(() => {
                    let user = state.crew_list.find(u => u.uuid === payload.user_uuid);

                    if (user) {
                        user.projects = payload.projects_uuids;
                        commit("UPDATE_USER_IN_CREW", user);
                    }
                })
        },
        async APPROVE_INVITE({dispatch, rootState}, uuid_invitation) {
            await axios
                .post(this.$api_url_locals + "/team/invitation/approve/" + uuid_invitation)
                .then(async resp => {
                    await dispatch("GET_TEAMS_LIST")
                        .then(() => {
                            if (rootState.teams.teams_list.length) {
                                let new_team = rootState.teams.teams_list.find(t => t.uuid === resp.data.data.team_role.team_uuid);

                                if (new_team) dispatch("CHANGE_SELECTED_TEAM", new_team);
                                else dispatch("CHANGE_SELECTED_TEAM", rootState.teams.teams_list[0]);
                            }
                        })
                })
        }
    },
    mutations: {
        SAVE_DELETED_MEMBER(state, member) {
            state.deleted_member = member
        },
        SAVE_CREW_LIST(state, list) {
            state.crew_list = list
        },
        SAVE_EDITED_MEMBER_EMAIL(state, email) {
            if (email) {
                state.edited_member_email = email;
                sessionStorage.setItem('edited_member_email', email);
            } else {
                state.edited_member_email = null;
                sessionStorage.removeItem('edited_member_email');
            }
        },
        UPDATE_USER_IN_CREW(state, user) {
            let i = state.crew_list.findIndex(m => m.uuid === user.uuid)
            if (~i) state.crew_list.splice(i, 1, user)
        },
        CLEAR_USER_FROM_CREW(state, email) {
            state.crew_list = state.crew_list.filter(m => m.email !== email)
        },
        CLEAR_CREW: (state) => {
            Object.assign(state, getDefaultState());
        }
    }
}
