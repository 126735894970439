import axios from "axios";

const getDefaultState = () => {
    return {
        notifications: [],
        notifications_unread_status: false,
        notifications_limit: 15,
        notifications_panel: false,
        notifications_settings_panel: false,
        notifications_all_loaded: false,
        notifications_types: [
            'key_comment',
            'order_execution',
            'translator_key_change',
            'developer_key_change '
        ],
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_NOTIFICATIONS({state, commit}, {unread, update, load_up}) {
            if (!state.notifications.length || update || state.notifications_unread_status || !state.notifications_all_loaded) {
                let url = this.$api_url_locals + '/notification/list';
                let last_id;

                if (state.notifications_unread_status || update) commit("REMOVE_NOTIFICATIONS")
                else if ((state.notifications.length > 0) && load_up) last_id = state.notifications[state.notifications.length - 1].id;

                await axios
                    .get(url, {
                        params: {
                            limit: state.notifications_limit,
                            only_unread: Boolean(unread),
                            last_id
                        }
                    })
                    .then(resp => {
                        let ns = resp.data.data.notifications;

                        if (ns.length) commit("SAVE_NOTIFICATIONS", ns);
                        else if (unread) commit("CHANGE_NOTIFICATIONS_UNREAD_STATUS", false);

                        if (ns.length < state.notifications_limit) state.notifications_all_loaded = true;
                    })
                    .catch((e) => {
                        throw new Error(e)
                    })
            }
        },
        async LOAD_NOTIFICATIONS_UNREAD_AVAILABLE({commit}) {
            await axios
                .get(this.$api_url_locals + '/notification/unread')
                .then(response => commit("CHANGE_NOTIFICATIONS_UNREAD_STATUS", response.data.data.available));
        },
        async READ_NOTIFICATIONS({state, dispatch, commit}, {all, ids}) {
            await axios
                .post(this.$api_url_locals + '/notification/read', {all: Boolean(all), ids})
                .then(() => {
                    if (Boolean(all) === true) {
                        state.notifications.forEach(n => n.read_at = Date.now().toString());
                        commit("CHANGE_NOTIFICATIONS_UNREAD_STATUS", false);
                    } else if (ids.length) {
                        ids.forEach(id => {
                            let item = state.notifications.find(n => n.id == id)
                            if (item) item.read_at = Date.now().toString()
                        })
                        dispatch("LOAD_NOTIFICATIONS_UNREAD_AVAILABLE")
                    }
                })
        },
    },
    mutations: {
        SAVE_NOTIFICATIONS: (state, notifications) => state.notifications = state.notifications.concat(notifications),
        REMOVE_NOTIFICATIONS: (state) => state.notifications = [],
        CHANGE_NOTIFICATIONS_UNREAD_STATUS: (state, bool) => state.notifications_unread_status = bool,
        CHANGE_NOTIFICATIONS_PANEL: (state) => {
            state.notifications_panel = !state.notifications_panel
        },
        CLOSE_NOTIFICATIONS_PANEL: (state) => {
            state.notifications_panel = false;
            state.notifications = [];
            state.notifications_all_loaded = false;
        },
        CHANGE_NOTIFICATIONS_SETTINGS_PANEL: (state, boolean) => state.notifications_settings_panel = boolean,
        CLEAR_NOTIFICATIONS: (state) => {
            Object.assign(state, getDefaultState());
        },
    },
}
