const getDefaultState = () => {
    return {
        image_popup: null,
        image_editor_popup: null,
        input_alert_popup: null,
        project_info_popup: null,
        user_verified_popup: false,
        translation_mode_popup: null,
        insert_manually_popup: false,
        placeholders_using_popup: false,
        need_unsubscribe_popup: false,
        limit_exceeded_popup: false,
    }
}

export default {
    state: () => getDefaultState(),
    mutations: {
        CHANGE_IMAGE_POPUP: (state, data) => {
            // data: [images: [url, url, url], start_index: i]
            state.image_popup = data
        },
        CHANGE_IMAGE_EDITOR_POPUP: (state, data) => {
            // data: [images: [url, url, url], start_index: i]
            state.image_editor_popup = data
        },
        SAVE_PROJECT_INFO_POPUP: (state, data) => state.project_info_popup = data,
        SAVE_USER_VERIFIED_POPUP: (state, boolean) => state.user_verified_popup = boolean,
        SAVE_TRANSLATION_MODE_POPUP: (state, boolean) => state.translation_mode_popup = boolean,
        SAVE_PLACEHOLDERS_USING_POPUP: (state, boolean) => state.placeholders_using_popup = boolean,
        SAVE_LIMIT_EXCEEDED_POPUP: (state, boolean) => state.limit_exceeded_popup = boolean,
        SAVE_NEED_UNSUBSCRIBE_POPUP: (state, boolean) => state.need_unsubscribe_popup = boolean,
        SAVE_INPUT_ALERT_POPUP: (state, boolean) => state.input_alert_popup = boolean,
        CHANGE_INSERT_MANUALLY_POPUP: (state, data) => state.insert_manually_popup = data,
    }
}
