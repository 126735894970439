const getDefaultState = () => {
    return {
        error_system: null,
        error_auth: null,
        error_server: null,
        error_dictionary: null,
        error_addition: null,
        error_chat: null,
        error_server_array: [],

        error_system_timeout: null,
        error_auth_timeout: null,
        error_server_timeout: null,
        error_dictionary_timeout: null,
        error_addition_timeout: null,
        error_chat_timeout: null,
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        // call mutation with payload: { name: 'auth', content: {error code}, url: api path }
        ERROR_SHOW({state}, {name, content, url}) {
            let delay = 0,
                err_name = 'error_' + name,
                err_timeout = err_name + '_timeout';

            if (state[err_name]) return;

            if (url) {
                console.log(url)
            }

            setTimeout(() => {
                state[err_timeout] = setTimeout(() => state[err_name] = null, 5000);
                state[err_name] = content || 'error';
            }, delay);
        },
    },
    mutations: {
        CHANGE_ERROR_STATUS: (state, data) => {
            state['error_' + data.name] = data.content ? data.content : true
        },
        CHANGE_ERROR_SERVER: (state, data) => {
            if (data.type === 'add') state.error_server_array.push(data.error)
            else {
                let index = state.error_server_array.findIndex(err => err === data.error)
                if (~index) state.error_server_array.splice(index, 1)
            }
        },
        CLOSE_ERROR: (state, type) => {
            state['error_' + type] = null
            clearTimeout(state['error_' + type + '_timeout'])
            state['error_' + type + '_timeout'] = null
        },
        CLOSE_ALL_ERRORS: (state) => {
            clearTimeout(state.error_system_timeout)
            clearTimeout(state.error_auth_timeout)
            clearTimeout(state.error_server_timeout)
            clearTimeout(state.error_dictionary_timeout)
            clearTimeout(state.error_addition_timeout)
            Object.assign(state, getDefaultState())
        }
    },
}
