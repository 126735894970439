module.exports = {
    single_tags: [
        'area',
        'base',
        'basefont',
        'bgsound',
        'br',
        'col',
        'command',
        'embed',
        'hr',
        'img',
        'input',
        'isindex',
        'keygen',
        'link',
        'meta',
        'param',
        'source',
        'track',
        'wbr'
    ],
    tagNameRegExp: /<\/?(\w+).*?>/,
    getPlaceholderName: (text) => {
        if (text) {
            return text.match(/[^.]:([^.]+)$|$\w/)?.[1]?.replace(/[[|\]|\\.|,|:|}]/g, '') || "";
        } else return "";
    },
}
