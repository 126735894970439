<template>
    <transition name="transition-alert">
        <div v-if="show" class="alert">
            <div class="alert_content"
                 :class="{detail_page, error, black, notice, cookies, patterns, content, no_padding}"
                 @mouseover="$emit('mouseover')"
            >
                <w-svg-icon v-if="icon_ok" class="alert_content__icon" folder-name="main" icon-name="iconOkCircle"/>
                <w-svg-icon v-else-if="icon" class="alert_content__icon" folder-name="favorites" icon-name="iconChoosed" fill="#48C68F"/>

                <div class="alert_content__text">
                    <slot/>
                </div>

                <div v-if="close" class="alert_content__close icon" @click.stop="closeAlert">
                    <w-svg-icon folder-name="main" icon-name="iconClose" fill="#ffffff"/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "w_alert",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            icon_ok: [Boolean],
            black: {
                type: Boolean,
                default: false
            },
            close: {
                type: Boolean,
                default: false
            },
            content: {
                type: Boolean,
                default: false
            },
            cookies: {
                type: Boolean,
                default: false
            },
            detail_page: {
                type: Boolean,
                default: false
            },
            error: {
                type: Boolean,
                default: false
            },
            notice: {
                type: Boolean,
                default: false
            },
            icon: {
                type: Boolean,
                default: false
            },
            no_padding: {
                type: Boolean,
                default: false
            },
            patterns: {
                type: Boolean,
                default: false
            },
            points: {
                type: Number
            },
        },
        methods: {
            closeAlert() {
                if (this.close) {
                    this.$emit('close')
                } else {
                    this.$store.commit('CLOSE_ALL_ERRORS')
                    this.$store.commit('CLOSE_ALL_ALERTS')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .alert {
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 3001;
        padding-bottom: 20px;
        pointer-events: none;
        overflow: hidden;

        &_content {
            display: flex;
            justify-content: space-between;
            border-radius: 20px;
            font-size: 20px;
            line-height: 24px;
            color: var(--w_color_white);
            padding: 18px 30px;
            max-width: 60%;
            box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
            align-items: center;
            background-color: var(--w_color_dark);
            pointer-events: auto;

            &__pattern {
                position: absolute !important;

                &.one {
                    left: 0;
                }

                &.two {
                    right: 0;
                }
            }

            &__icon {
                margin-right: 9px;
                width: 20px;
                height: 20px;
                min-width: 20px;
            }

            &__close {
                height: 28px;
                width: 28px;
                min-width: 28px;
                opacity: .6;
                margin-left: 22px;
                cursor: pointer;

                svg {
                    width: 12px;
                    height: 12px;
                }
            }

            &__points {
                position: relative;
                width: 44px;
                min-width: 44px;
                height: 44px;
                margin-left: 10px;

                &-rating {
                    z-index: 1;
                    color: var(--w_color_white);
                    font-size: 15px;
                    line-height: 22px;
                }

                &-background {
                    position: absolute !important;
                    top: 0;
                    left: 0;
                    border-radius: 50%;
                    pointer-events: none;
                    width: 44px;
                    height: 44px;
                }
            }

            &.detail_page {
                max-width: none !important;
                width: 100%;

                .alert_content__text {
                    width: 100%;
                }
            }

            &.error {
                background: var(--w_color_red) !important;
                max-width: 80%;
            }

            &.black {
                background: var(--w_color_black) !important;
                color: var(--w_color_white) !important;
                max-width: 80%;
            }

            &.notice {
                font-size: 14px;
                line-height: 12px;
                border-radius: 12px;
                padding: 16px 12px;
            }

            &.cookies, &.patterns {
                text-align: left;
                cursor: default;
                user-select: none;

                .alert_content__close {
                    border-radius: 50%;
                    background: #4C4B51;
                }
            }

            &.cookies {
                //background-image: url("../../assets/images/other/cookiesPattern.png");
            }

            &.patterns {
                //background-image: url("../../assets/images/other/alertPattern.png");
                background-position: top right;
                background-repeat: no-repeat;
            }

            &.content {
                margin-left: 238px;
                left: 0;
            }

            &.no_padding {
                padding: 0;
                overflow: hidden;
            }
        }
    }

    .transition-alert {
        &-enter-from, &-leave-to {
            bottom: 0;

            .alert_content {
                transform: translateY(150%);
                will-change: transform;
            }
        }

        &-enter-active, &-leave-active {
            transition: bottom 300ms ease;

            .alert_content {
                transition: transform 300ms ease;
            }
        }
    }

    @media (max-width: 1440px) and (min-width: 981px) {
        .alert {
            padding-bottom: 14px;

            &_content {
                border-radius: 14px;
                font-size: 14px;
                line-height: 18px;
                padding: 12px 20px;

                &__icon {
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                }

                &__close {
                    height: 18px;
                    width: 18px;
                    min-width: 18px;
                    margin-left: 16px;

                    svg {
                        width: 8px;
                        height: 8px;
                    }
                }

                &__points {
                    width: 32px;
                    min-width: 32px;
                    height: 32px;
                    margin-left: 8px;

                    &-rating {
                        font-size: 12px;
                        line-height: 16px;
                    }

                    &-background {
                        width: 32px;
                        height: 32px;
                    }
                }

                &.content {
                    margin-left: 180px;
                }

                &.notice {
                    font-size: 9px;
                    line-height: 8px;
                    border-radius: 8px;
                    padding: 11px 8px;
                }
            }
        }
    }

    @media screen and (max-width: 980px) {
        .alert {
            position: fixed;
            bottom: 0;
            display: flex;
            justify-content: center;
            padding-bottom: 0;
            left: 0 !important;
            width: 100vw !important;

            &_content {
                width: inherit;
                border-radius: 0;
                font-size: 16px;
                line-height: 20px;
                color: var(--w_color_white);
                padding: 12px;
                max-width: none !important;
                box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
                align-items: center;
                justify-content: center;
                background-color: var(--w_color_dark);

                &__close {
                    margin-left: 10px;
                }
            }
        }

        @supports (-webkit-touch-callout: none) {
            @media screen and (display-mode: standalone) {
                .alert_content {
                    padding: 12px 12px 42px;
                }
            }
        }
    }

    [data-theme='dark'] {
        .alert_content {
            color: var(--w_color_white);
            background-color: #2D2C33 !important;

            &.error {
                background: var(--w_color_red) !important;
            }
        }
    }
</style>
