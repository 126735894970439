<template>
    <span class="w_loading_min loading none-events" :class="{blur}" :style="style">
        <svg class="w_loading_min__icon" :data-color="color" :style="style" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" :stroke-width="stroke_width"></circle>
            <circle class="circle" cx="25" cy="25" r="20" fill="none" :stroke-width="stroke_width"></circle>
        </svg>
    </span>
</template>

<script>
    export default {
        name: "w_loading_min",
        props: {
            blur: {
                type: Boolean,
                default: false
            },
            color: {
                type: String,
                default: 'white',
                validator(value) {
                    return ['white', 'dark', 'green', 'grey', 'white-only', 'red'].includes(value)
                }
            },
            width: {
                type: [Number, String],
                default: 28
            },
            s_width: {
                type: [Number, String]
            },
        },
        data() {
            return {
                stroke_width: 5
            }
        },
        computed: {
            style() {
                return {
                    width: this.width + 'px',
                    height: this.width + 'px',
                    minWidth: this.width + 'px',
                    minHeight: this.width + 'px',
                }
            }
        },
        created() {
            this.stroke_width = Math.floor(this.width * 0.2);

            if (this.s_width) this.stroke_width = Number(this.s_width);
            else if (this.width > 50) this.stroke_width = 3;
            else if (!this.stroke_width) this.stroke_width = 5;
        }
    }
</script>

<style lang="scss" scoped>
    .w_loading_min {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        &.blur {
            backdrop-filter: blur(4px);
            background-color: rgba(245, 246, 250, 0.3);
        }

        &__icon {
            left: 50%;
            top: 50%;
            animation: rotate 2s linear infinite;

            .path, .circle {
                stroke-linecap: round;
            }

            .path {
                animation: dash 1.5s ease-in-out infinite;
            }

            .circle {
                stroke-dasharray: 1;
                stroke-dashoffset: 0;
            }

            &[data-color='white'] {
                .path {
                    stroke: hsl(0, 0%, 100%);
                }
                .circle {
                    stroke: hsl(0, 0%, 100%, 20%);
                }
            }

            &[data-color='dark'] {
                .path {
                    stroke: hsl(217, 14%, 63%);
                }
                .circle {
                    stroke: hsl(217, 14%, 63%, 20%);
                }
            }

            &[data-color='green'] {
                .path {
                    stroke: hsl(154, 53%, 53%);
                }
                .circle {
                    stroke: hsl(154, 53%, 53%, 20%);
                }
            }

            &[data-color='grey'] {
                .path {
                    stroke: hsl(240, 20%, 98%);
                }
                .circle {
                    stroke: hsl(240, 20%, 98%, 20%);
                }
            }

            &[data-color='white-only'] {
                .path {
                    stroke: hsl(0, 0%, 100%);
                }
                .circle {
                    stroke: hsl(0, 0%, 100%, 20%);
                }
            }

            &[data-color='red'] {
                .path {
                    stroke: hsl(3 ,96%, 62%);
                }
                .circle {
                    stroke: hsl(3 ,96%, 62%, 20%);
                }
            }
        }
    }

    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }

    [data-theme='dark'] {
        .w_loading_min {
            &__icon {
                &[data-color='white'] {
                    .path {
                        stroke: hsl(210, 39%, 16%);
                    }
                    .circle {
                        stroke: hsl(210, 39%, 16%, 20%);
                    }
                }

                &[data-color='dark'] {
                    .path {
                        stroke: hsl(211, 27%, 85%);
                    }
                    .circle {
                        stroke: hsl(211, 27%, 85%, 20%);
                    }
                }

                &[data-color='grey'] {
                    .path {
                        stroke: hsl(212, 33%, 23%);
                    }
                    .circle {
                        stroke: hsl(212, 33%, 23%, 20%);
                    }
                }
            }
        }
    }
</style>
