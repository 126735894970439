<template>
    <img class="w_flag"
         :src="pathToImg"
         :alt="code"
         :title="lang_name"
         @mouseenter="getLanguage"
    >
</template>

<script>
    export default {
        name: 'w_flag',
        props: {
            code: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                lang_name: '',
                pathToImg: ''
            }
        },
        created() {
            try {
                this.pathToImg = require(`@/assets/icons/ico_flag/${this.code}.svg`);
            } catch {
                this.pathToImg = require(`@/assets/icons/ico_flag/plug.svg`);
            }
        },
        methods: {
            getLanguage() {
                let name = ''
                try {
                    name = this.$store.state.languages.languages.find(lang => lang.code === this.code || lang.code_two_signs === this.code).name
                } catch (e) {
                    name = this.code
                }
                if (name) this.lang_name = name[0].toUpperCase() + name.slice(1)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .w_flag {
        position: relative;
        box-sizing: content-box;
        border-radius: 50%;
        max-width: 32px;
        max-height: 32px;
        min-width: 8px;
        min-height: 8px;
        cursor: auto;

        &:not(.none-events) {
            pointer-events: auto !important;
        }
    }
</style>
