import Axios from 'axios';
import store from '@/store';
import CANCEL_TOKENS from "@/assets/js/cancel_tokens";
import SUPPORTED_LOCALES from "@/../supported_locales.js";

const
    token = () => store.state.auth.token,
    token_type = store.state.auth.token_type,
    getLocaleRegex = () => {
        let reg = [];
        SUPPORTED_LOCALES.forEach(locale => reg.push(locale.code));
        return reg;
    };

let has_error = false,
    internet_connection = true;

Axios.baseURL = store.state.other.development_host ? store.$api_url : 'https://localit.io/api/v1';
Axios.timeout = 5000;

if (token()) Axios.defaults.headers.common['authorization'] = token_type + ' ' + token();

Axios.interceptors.request.use(async function (config) {
    let url = config.url;

    if (!url.includes('/api/v1/user/refresh-token')) {
        let need_update_token = await store.dispatch("CHECK_TOKEN_LIFE")
        if (need_update_token) {
            await store.dispatch('REFRESH_TOKEN').then(t => config.headers.authorization = 'Bearer ' + t)
        }
    }

    if (!config.cancelToken) {
        let controller = CANCEL_TOKENS.get(url);
        let same_data = false;
        let same_params = false;

        if (controller && (!config.data || (controller.data === JSON.stringify(config.data)))) same_data = true;
        if (controller && (!config.params || (controller.params === JSON.stringify(config.params)))) same_params = true;
        if (same_data && same_params) controller.ab_control?.abort();

        controller = {
            "ab_control": new AbortController(),
            "data": JSON.stringify(config.data),
            "params": JSON.stringify(config.params)
        }

        CANCEL_TOKENS.set(url, controller)

        config.signal = controller.ab_control.signal
    }

    if (has_error) store.commit("CLOSE_ALL_ERRORS")
    if (!internet_connection) {
        internet_connection = true
        store.commit("SAVE_INTERNET_CONNECTION", true)
    }

    if ((url.includes('://wola.') || url.includes('://locals.wola.') || url.includes('://localit.')) && !config.headers['local']) {
        let local = store.getters.LOCALE.dictionary_code
        if (!local) {
            let params = location.pathname.split('/')
            if (getLocaleRegex().includes(params[1])) {
                let l = store.state.languages.languages.find(l => l.code_two_signs === params[1])
                local = l ? l.code : 'eng'
            } else local = 'eng'
        }
        config.headers['local'] = local
    }

    return config;
});

Axios.interceptors.response.use(
    response => {
        has_error = false
        CANCEL_TOKENS.delete(response.config.url)
        return response
    },
    error => {
        has_error = true
        if (error.code === "ERR_CANCELED") {
            CANCEL_TOKENS.delete(error.config.url)
            throw new Error(error)
        } else if (error.message.includes('Request canceled')) {
            throw new Error(error)
        } else if (error.message === 'Network Error') {
            if (navigator.onLine) {
                store.commit("CHANGE_ERROR_STATUS", {name: 'server', content: 'Network Error'})
            } else {
                internet_connection = false;
                store.commit("SAVE_INTERNET_CONNECTION", false)
            }
            throw new Error('Network Error')
        } else if (error.response) {
            let err,
                blob_err,
                isBlob = error.config.responseType?.toLowerCase() === 'blob';

            if (isBlob) {
                blob_err = error.response.data;
            } else if (error.response.data.error) {
                err = error.response.data.error;
            } else if (error.response.data.errors) {
                err = error.response.data.errors[0];
            }

            if (error.response.status === 304) return
            else if (err && error.response.status === 400) {
                if (err.code === 'INVALID_LOGIN_OR_PASSWORD') {
                    store.dispatch('ERROR_SHOW', {name: 'auth', content: 'incorrect_login_or_password'})
                } else if (err.code === 'USER_LOGIN_BLOCKED_EXCEPTION') {
                    store.dispatch('ERROR_SHOW', {name: 'auth', content: 'blocked_access_account'})
                } else if (err.code === 'ERROR_KEY_BLOCKED_BY_ORDER') {
                    store.dispatch('ERROR_SHOW', {name: 'system', content: 'error_key_blocked_by_order'})
                } else if (err.code === 'ERROR_UNTRANSLATED_ORDER_LANGUAGE_EXISTS') {
                    store.dispatch('ERROR_SHOW', {name: 'system', content: 'error_key_blocked_by_order'})
                } else if (err.code === 'ERROR_TRANSLATION_EXISTS') {
                    store.dispatch('ERROR_SHOW', {name: 'system', content: 'error_translation_exists'})
                }
            } else if (error.response.status === 401) {
                if (error.response.config.url.includes('/user/logout')) console.info('logout');
                else store.dispatch("REFRESH_TOKEN")
            } else if (error.response.status === 403) {
                // if (!error.response.config.url.includes('/project/') && !error.response.config.url.includes('/team/invitation')) {
                //     store.dispatch("ERROR_SHOW", {
                //         name: 'server',
                //         content: error.response.status,
                //         url: error.response.config.url
                //     })
                // }
            } else if (error.response.status === 500 || error.response.status === 502) {
                console.info('Localit: Server error')
                store.dispatch("ERROR_SHOW", {
                    name: 'server',
                    content: error.response.status,
                    url: error.response.config.url
                })
            } else if (error.response.status === 503) {
                console.info('Localit: Service Temporarily Unavailable')
                store.dispatch("ERROR_SHOW", {
                    name: 'server',
                    content: error.response.status,
                    url: error.response.config.url
                })
            } else if (error.response.status === 504) {
                console.info('Localit: Server time out')
                store.dispatch("ERROR_SHOW", {
                    name: 'server',
                    content: error.response.status,
                    url: error.response.config.url
                })
            } else if (error.response.status === 700) {
                console.info('Localit: Server time out')
                store.dispatch("ERROR_SHOW", {
                    name: 'server',
                    content: error.response.status,
                    url: error.response.config.url
                })
            }
            return Promise.reject(blob_err || err);
            // throw new Error(error.response.data.errors[0] || 'Axios error')
        } else {
            console.info('Localit: Axios error: ' + error)
        }

        return Promise.reject(error);
    }
);

export default Axios
