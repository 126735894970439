import axios from "axios";
import router from "@/router";

const getDefaultState = () => {
    return {
        teams_list: [],
        selected_team: {},
        deleted_team_uuid: null
    }
}
export default {
    state: () => getDefaultState(),
    getters: {
        SELECTED_TEAM: state => state.selected_team
    },
    actions: {
        async GET_TEAMS_LIST({getters, dispatch, commit}, auth) {
            await axios
                .get(this.$api_url_locals + "/team/list")
                .then(response => {
                    commit("SAVE_TEAMS_LIST", response.data.data.teams);

                    if (!auth) {
                        if (!response.data.data.teams.length) {
                            if (router.currentRoute._value.name !== 'PromoPage') router.replace({name: 'CreateNewTeamPage'});
                        } else if (!getters.SELECTED_TEAM.uuid) {
                            dispatch("CHANGE_SELECTED_TEAM", response.data.data.teams[0]);
                        }
                    }
                });
        },
        async CREATE_NEW_TEAM({dispatch, commit}, payload) {
            let fData = new FormData();
            if (payload.name) fData.append('name', payload.name);
            if (payload.avatar) fData.append('avatar', payload.avatar);

            await axios
                .post(this.$api_url_locals + "/team", fData)
                .then(async response => {
                    await commit("SAVE_NEW_TEAM", response.data.data.team);
                    await dispatch("CHANGE_SELECTED_TEAM", response.data.data.team);
                    await dispatch("GET_CREW_LIST", "update");
                    await router.push({
                        name: 'TeamProjectsPage',
                        params: {team_uuid: response.data.data.team.uuid}
                    })
                })
                .catch((err) => {
                    throw new Error(err)
                })
        },
        async CHANGE_TEAM({dispatch, commit}, payload) {
            let fData = new FormData();
            fData.append('name', payload.form_data.name);
            if (payload.form_data.avatar) fData.append('avatar', payload.form_data.avatar);
            else if (payload.form_data.is_avatar_deletion) fData.append('is_avatar_deletion', true);

            await axios
                .put(this.$api_url_locals + "/team/" + payload.id, fData)
                .then(response => {
                    commit("SAVE_NEW_TEAM", response.data.data.team);
                    dispatch("CHANGE_SELECTED_TEAM", response.data.data.team);
                    router.push({
                        name: 'TeamProjectsPage',
                        params: {team_uuid: response.data.data.team.uuid}
                    })
                })
                .catch((err) => {
                    dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    throw new Error(err);
                })
        },
        CHANGE_SELECTED_TEAM({commit}, team) {
            setTimeout(() => commit("SAVE_SCROLL", {name: 'TeamProjectsPage', position: 0}), 50);
            commit("SAVE_SELECTED_TEAM", team);
            commit("CLEAR_PROJECTS");
            commit("CLEAR_CREW");
            commit("SAVE_ORDERS_LIST");
            commit("SAVE_CURRENT_RATE", null);
        },
        async DELETE_TEAM({state, commit, rootState}, uuid) {
            let id = uuid || state.deleted_team_uuid;

            await axios
                .delete(this.$api_url_locals + "/team/" + id)
                .then(() => {
                    rootState
                        .projects
                        .projects_list
                        .forEach(p => commit("DELETE_PROJECT_DOWNLOAD_OPTIONS", p.uuid));

                    commit("DELETE_TEAM_FROM_LIST", id);
                })
                .catch((err) => {
                    throw Error(err.code)
                })
        }
    },
    mutations: {
        SAVE_TEAMS_LIST(state, list) {
            state.teams_list = list
        },
        SAVE_NEW_TEAM(state, team) {
            let index = state.teams_list.findIndex(t => t.uuid === team.uuid)

            if (~index) state.teams_list.splice(index, 1, team)
            else state.teams_list.push(team)
        },
        SAVE_SELECTED_TEAM(state, team) {
            state.selected_team = team ? team : {}
        },
        SAVE_DELETED_TEAM_UUID(state, team) {
            state.deleted_team_uuid = team
        },
        async DELETE_TEAM_FROM_LIST(state, uuid) {
            state.teams_list = await state.teams_list.filter(t => t.uuid !== uuid);

            if (state.teams_list[0]) {
                let first_team = document.getElementById(state.teams_list[0].uuid);
                if (first_team) first_team.click();
            } else {
                state.selected_team = {};
                await router.replace({'name': 'CreateNewTeamPage'});
            }
        },
        CLEAR_TEAMS: (state) => {
            Object.assign(state, getDefaultState());
        }
    }
}
