<template>
    <div class="w_loading_list">
        <div class="w_loading_list__container">
            <div class="w_loading_list__container-element" v-for="i in 5" :key="i"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "w_loading_list",
        props: {
            is_observer: {
                type: Boolean,
                default: false
            },
        },
        emits: ['changeObserver'],
        mounted() {
            if (this.is_observer) {
                this.observer = new IntersectionObserver((entries) => {
                    this.$emit('changeObserver', entries[0].isIntersecting)
                }, {
                    threshold: this.threshold || 0
                });

                this.$nextTick(() => this.observer.observe(this.$el))
            }
        }
    }
</script>

<style lang="scss" scoped>
    $width: 8px;
    $height: 8px;
    $move_height: 12px;
    $fly_step: 5px;

    .w_loading_list {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;

        &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 20px;
            width: 72px;

            &-element {
                width: $width;
                height: $height;
                border-radius: 50%;
                background-color: var(--w_color_grey3);
                transition: .3s;
                animation-duration: 1200ms;
                animation-iteration-count: infinite;

                &:nth-child(odd) {
                    animation-name: branded-loader-odd;
                }
                &:nth-child(even) {
                    animation-name: branded-loader-even;
                }
            }
        }
    }

    @keyframes branded-loader-odd {
        0%, 80%, 100% {
            transform: translateY($fly_step);
            height: $height;
        }
        20%, 60% {
            height: $move_height;
        }
        40% {
            transform: translateY(-$fly_step);
            height: $height;
        }
    }
    @keyframes branded-loader-even {
        0%, 80%, 100% {
            transform: translateY(-$fly_step);
            height: $height;
        }
        20%, 60% {
            height: $move_height;
        }
        40% {
            transform: translateY($fly_step);
            height: $height;
        }
    }

    @media (max-width: 1440px) and (min-width: 981px) {
        $width: 6px;
        $height: 6px;
        $move_height: 10px;
        $fly_step: 4px;

        .w_loading_list {
            &__container {
                height: 14px;
                width: 48px;

                &-element {
                    width: $width;
                    height: $height;
                }
            }
        }

        @keyframes branded-loader-odd {
            0%, 80%, 100% {
                transform: translateY($fly_step);
                height: $height;
            }
            20%, 60% {
                height: $move_height;
            }
            40% {
                transform: translateY(-$fly_step);
                height: $height;
            }
        }
        @keyframes branded-loader-even {
            0%, 80%, 100% {
                transform: translateY(-$fly_step);
                height: $height;
            }
            20%, 60% {
                height: $move_height;
            }
            40% {
                transform: translateY($fly_step);
                height: $height;
            }
        }
    }

    [data-theme='dark'] {
        .w_loading_list__container-element {
            background-color: var(--w_color_grey5);
        }
    }
</style>
