<template>
    <svg v-cloak
         :viewBox="viewBox"
         :width="svgWidth || 1"
         :height="svgHeight || 1"
         :style="svgStyle"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         @click.stop="$emit('click')"
    />
</template>

<script>
    import {parse} from 'postsvg'
    import {render} from 'posthtml-render'

    const cache = new Map()

    export default {
        name: 'w_svg_icon',
        props: {
            iconName: {
                type: String,
                required: true
            },
            folderName: {
                type: String,
                required: true
            },
            width: [Number, String],
            height: [Number, String],
            iconViewBox: [String],
            stroke: String,
            fill: String,
            rect: String,
            generateReadyEvent: {
                type: Boolean,
                default: false
            },
            clickable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                svgString: '',
                show: false
            }
        },
        computed: {
            filepath() {
                return require(`@/assets/icons/${this.folderName}/${this.iconName}.svg`)
            },
            parsedSVG() {
                return this.svgString ? parse(this.svgString) : null
            },
            viewBox() {
                if (this.iconViewBox) return this.iconViewBox
                else return this.parsedSVG ? this.parsedSVG.root.attrs.viewBox : '0 0 20 20'
            },
            svgWidth() {
                return this.parsedSVG && !this.width ? this.parsedSVG.root.attrs.width : this.width
            },
            svgHeight() {
                return this.parsedSVG && !this.height ? this.parsedSVG.root.attrs.height : this.height
            },
            svgStyle() {
                return {
                    pointerEvents: this.clickable ? '' : 'none',
                    cursor: this.clickable ? 'pointer' : '',
                    minWidth: this.width + (isNaN(this.width) ? 'px' : ''),
                    maxWidth: this.width + (isNaN(this.width) ? 'px' : ''),
                    maxHeight: this.height + (isNaN(this.height) ? 'px' : '')
                }
            }
        },
        watch: {
            filepath: {
                immediate: true,
                handler: 'loadFile'
            },
            svgString: 'refreshSvg',
            stroke: 'refreshSvg',
            fill: 'refreshSvg',
            rect: 'refreshSvg',
        },
        methods: {
            loadFile() {
                this.getSvgIconText()
                    .then(responseText => this.svgString = responseText)
                    .catch(error => console.error('Error loading SVG-file', error))
            },
            getSvgIconText() {
                const url = this.filepath

                if (!cache.has(url)) {
                    try {
                        cache.set(url, fetch(url).then(r => r.text()))
                    } catch (e) {
                        cache.delete(url)
                    }
                }

                return cache.has(url)
                    ? cache.get(url)
                    : Promise.reject(new Error('No SVG file in local cache'))
            },
            refreshSvg() {
                if (this.parsedSVG) {
                    Promise.resolve(this.parsedSVG)
                        .then(svgTree => {
                            if (this.stroke) svgTree.each('path', node => (node.attrs.stroke ? node.attrs.stroke = this.stroke : false))
                            if (this.fill) svgTree.each('path', node => (node.attrs.fill ? node.attrs.fill = this.fill : false))
                            if (this.rect) svgTree.each('rect', node => (node.attrs.fill ? node.attrs.fill = this.rect : false))
                            return svgTree
                        })
                        .then(svgTree => render(svgTree.root.content))
                        .then(svgHtml => (this.$el.innerHTML = svgHtml))
                        .then(() => this.generateReadyEvent && this.$emit('ready'))
                        .catch(error => {
                            console.error('Error while updating SVG', error)
                            this.$emit('error', error)
                        })
                }
            }
        },
        emits: ['ready', 'error', 'click']
    }
</script>

<style scoped>
    svg {
        display: inline-block;
        vertical-align: baseline;
    }

    svg:deep(*) {
        pointer-events: none;
    }

    svg, svg path {
        transition: fill 0.3s, stroke 0.3s;
    }
</style>
