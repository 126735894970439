<template>
    <img :src="filepath" :alt="iconName" :title="title"/>
</template>

<script>
    export default {
        name: 'w_img_icon',
        props: {
            iconName: {
                type: String,
                required: true
            },
            folderName: {
                type: String,
                required: true
            },
            title: {
                type: String
            }
        },
        computed: {
            filepath() {
                return require(`@/assets/icons/${this.folderName}/${this.iconName}.svg`)
            }
        }
    }
</script>

<style scoped>
    svg {
        display: inline-block;
        vertical-align: baseline;
    }

    svg:deep(*) {
        pointer-events: none;
    }

    svg, svg path {
        transition: fill 0.3s, stroke 0.3s;
    }
</style>
