import router from "@/router";
import axios from "axios";
import UNFULFILLED_ACTIONS from "../../assets/js/unfulfilled_actions";

let sourceRefresh,
    can_refresh = true,
    can_refresh_timeout;

const getDefaultState = () => {
    return {
        token: '',
        token_type: '',
        expires_in: '',
        refresh_token: '',
        authorization_date: '',
        google_auth: false,
        apple_auth: false,
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        isLoggedIn: state => !!state.token
    },
    actions: {
        async LOGIN({commit, dispatch}, user) {
            await commit("CHANGE_LOADING_AUTH_STATUS", true)
            await commit("CLOSE_ERROR", 'auth')
            await axios
                .post(this.$api_url + "/user/login", user)
                .then(async resp => {
                    await dispatch('FIX_AUTH_STATUS', resp.data).finally(async () => {
                        await dispatch('FINISH_AUTH', 'old_user');
                    });
                })
                .catch(async (err) => {
                    // await dispatch('FIX_LOGOUT_STATUS');
                    throw Error(err)
                })
                .finally(() => commit("CHANGE_LOADING_AUTH_STATUS", false))
        },
        async REGISTRATION({commit, dispatch, rootState}, payload) {
            await commit("CHANGE_LOADING_AUTH_STATUS", true);

            const
                data = {
                    "email": payload.email,
                    "nickname": payload.nickname,
                    "password": payload.password,
                    "platform": "WEB",
                    "source": "localit"
                };

            delete data['avatar']; // don't delete

            await axios
                .post(this.$api_url + "/user/registration", data)
                .then(async resp => {
                    await localStorage.setItem('verified_popup', Date.now());
                    await dispatch("FIX_AUTH_STATUS", resp.data);
                    await dispatch("REGISTRATION_USER_LANGUAGES", payload);

                    if (!rootState.profile.native_lang) await router.push({name: 'addNativeLang'});
                })
                .catch(async err => {
                    await dispatch('FIX_LOGOUT_STATUS');
                    throw Error(err);
                })
                .finally(() => commit("CHANGE_LOADING_AUTH_STATUS", false))
        },
        async LOGOUT({state, dispatch}) {
            if (state.token) {
                await axios
                    .post(this.$api_url + "/user/logout")
                    .catch((err) => {
                        throw Error(err);
                    })
                    .finally(() => dispatch("FIX_LOGOUT_STATUS"))
            } else await dispatch("FIX_LOGOUT_STATUS");
        },
        async DELETE_ACCOUNT({state, getters, dispatch, commit}) {
            if (state.token) {
                await commit("CHANGE_DELETED_ACCOUNT_LOADING", true)
                await axios
                    .post(this.$api_url + "/user/delete")
                    .then(async () => {
                        if (getters.USER.nickname) await commit("CLEAR_USER_IN_HISTORY", getters.USER.nickname);
                        await dispatch("FIX_LOGOUT_STATUS");
                    })
                    .catch((err) => {
                        throw Error(err);
                    })
                    .finally(() => commit("CHANGE_DELETED_ACCOUNT_LOADING", false))
            } else await dispatch("FIX_LOGOUT_STATUS");
        },
        async UPDATE_SOCIAL_PROFILE({getters, commit, dispatch}, payload) {
            commit("CHANGE_LOADING_AUTH_STATUS", true)
            const user = {
                "nickname": payload.nickname
            }

            return new Promise((resolve, reject) => {
                if (getters.USER.need_to_set_nickname) {
                    dispatch("EDIT_USER_PROFILE", user)
                        .then(() => {
                            dispatch("REGISTRATION_USER_LANGUAGES", payload)
                                .then((r) => resolve(r))
                                .catch((e) => reject(e))
                        })
                        .catch(err => reject(err))
                        .finally(() => commit("CHANGE_LOADING_AUTH_STATUS", false))
                } else {
                    dispatch("REGISTRATION_USER_LANGUAGES", payload)
                        .then((r) => resolve(r))
                        .catch((e) => reject(e))
                }
            })
        },
        async REGISTRATION_USER_LANGUAGES({commit}, payload) {
            let languages = [{
                lang_code: payload.native_lang_code,
                level: "NATIVE"
            }]

            return new Promise((resolve, reject) => {
                axios
                    .post(this.$api_url + "/user/languages", {languages})
                    .then(async resp => {
                        await commit("SAVE_USER_LANG", resp.data.data)
                        await resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        async FINISH_AUTH({getters, dispatch, commit, rootState}, type) {
            if (getters.USER.need_to_set_nickname || !getters.USER.nickname) {
                await router.push({name: 'CompleteSocialProfile', params: {step: 'nickname'}});
            } else if (!rootState.profile.native_lang) {
                await router.push({name: 'CompleteSocialProfile', params: {step: 'native_language'}});
            } else {
                if (rootState.other.before_auth_link) {
                    await router.replace(rootState.other.before_auth_link);
                    await commit("SAVE_BEFORE_AUTH_LINK", null);
                } else if (!rootState.teams.teams_list.length) {
                    await router.replace({name: 'CreateNewTeamPage'});
                } else {
                    if (!getters.SELECTED_TEAM.uuid) await dispatch("CHANGE_SELECTED_TEAM", rootState.teams.teams_list[0]);
                    await router.push({name: 'TeamProjectsPage', params: {team_uuid: getters.SELECTED_TEAM.uuid}});
                }

                if (type === 'old_user') setTimeout(() => commit("SAVE_USER_VERIFIED_POPUP", !getters.USER.is_email_verified), 1000);
            }
        },
        async FIX_AUTH_STATUS({dispatch, commit}, resp) {
            const token = resp['access_token'],
                token_type = resp['token_type'],
                expires_in = resp['expires_in'],
                refresh_token = resp['refresh_token'];

            axios.defaults.headers.common['authorization'] = token_type + ' ' + token;

            if (resp['google']) await commit('SAVE_GOOGLE_AUTH', true);
            if (resp['apple']) await commit('SAVE_APPLE_AUTH', true);

            await commit('SAVE_TOKEN', token);
            await commit('SAVE_TOKEN_TYPE', token_type);
            await commit('SAVE_EXPIRES_IN', expires_in);
            await commit('SAVE_REFRESH_TOKEN', refresh_token);
            await commit('SAVE_AUTHORIZATION_DATE');
            await commit('CLEAR_CACHE');
            await dispatch('LOAD_USER');
            await dispatch('LOAD_NOTIFICATIONS_UNREAD_AVAILABLE');
            await dispatch('GET_TEAMS_LIST', true);
        },
        async FIX_LOGOUT_STATUS({dispatch, commit}) {
            let localKeys = [
                'sentFirebaseMessagingToken',
                'verified_popup',
                'hidden_avatar_banner',
                'selected_platform_placeholders',
                'main_translation_update_type'
            ];

            localKeys.forEach((item) => localStorage.removeItem(item));
            sessionStorage.clear();
            delete axios.defaults.headers.common['authorization'];
            await commit('CLEAR_AUTH');
            await commit('CLEAR_USER');
            await commit('CLEAR_CACHE');
            await commit('CLEAR_TEAMS');
            await commit('CLEAR_CREW');
            await dispatch("CHANGE_SELECTED_TEAM");
            await router.push({name: 'Auth'})
        },
        async FORGET_PASSWORD({commit}, email) {
            commit("CHANGE_LOADING_AUTH_STATUS", true)
            await axios
                .post(this.$api_url + "/user/password-reset/reset", {email, "source": "localit"})
                .finally(() => commit("CHANGE_LOADING_AUTH_STATUS", false))
        },
        async RESET_PASSWORD({commit}, data) {
            await commit("CHANGE_LOADING_AUTH_STATUS", true)
            await axios
                .post(this.$api_url + "/user/password-reset/update", data)
                .finally(() => commit("CHANGE_LOADING_AUTH_STATUS", false))
        },
        async CHECK_TOKEN_LIFE({state, getters}) {
            if (getters.isLoggedIn) {
                let date_now = Number(Date.now().toString()),
                    date_auth = state.authorization_date,
                    expires_in = state.expires_in,
                    date_diff = Math.floor((date_now - date_auth) / 1000),
                    allowable_stock = Math.floor(expires_in * 0.99);

                return (expires_in - date_diff) < allowable_stock;
            } else return false
        },
        REFRESH_TOKEN({state, getters, commit, dispatch}) {
            return new Promise(resolve => {
                let new_token = ''
                if (!getters.isLoggedIn) {
                    resolve()
                } else if (can_refresh) {
                    can_refresh = false;
                    commit("CHANGE_LOADING_REFRESH_TOKEN_STATUS", true);

                    if (state.refresh_token) {
                        if (sourceRefresh) sourceRefresh.cancel('tooltips');
                        sourceRefresh = axios.CancelToken.source();

                        axios
                            .post(this.$api_url + '/user/refresh-token', {'refresh_token': state.refresh_token}, {cancelToken: sourceRefresh.token})
                            .then(async (resp) => {
                                const token = resp.data['access_token'],
                                    token_type = resp.data['token_type'],
                                    expires_in = resp.data['expires_in'],
                                    refresh_token = resp.data['refresh_token'];

                                axios.defaults.headers.common['authorization'] = token_type + ' ' + token;

                                new_token = token;
                                await commit('SAVE_TOKEN', token);
                                await commit('SAVE_TOKEN_TYPE', token_type);
                                await commit('SAVE_EXPIRES_IN', expires_in);
                                await commit('SAVE_REFRESH_TOKEN', refresh_token);
                                await commit('SAVE_AUTHORIZATION_DATE');
                                await UNFULFILLED_ACTIONS.forEach((payload, name) => dispatch(name, payload));
                                await UNFULFILLED_ACTIONS.clear();
                            })
                            .catch(async () => {
                                await dispatch("FIX_LOGOUT_STATUS");
                                if (!location.pathname.includes('/auth')) {
                                    await dispatch("CLOSE_PANELS");
                                    await router.push('/auth');
                                }
                            })
                            .finally(async () => {
                                await commit("CHANGE_LOADING_REFRESH_TOKEN_STATUS", false);
                                await clearTimeout(can_refresh_timeout);
                                can_refresh_timeout = setTimeout(() => can_refresh = true, 500);
                                resolve(new_token);
                            })
                    } else {
                        dispatch("FIX_LOGOUT_STATUS");
                        dispatch("CLOSE_PANELS");
                        router.push('/auth');
                        commit("CHANGE_LOADING_REFRESH_TOKEN_STATUS", false);
                        setTimeout(() => can_refresh = true, 500);
                        resolve();
                    }
                } else {
                    let interval = setInterval(() => {
                        if (can_refresh) {
                            clearInterval(interval);
                            resolve(state.token);
                        }
                    }, 150)
                }
            })
        }
    },
    mutations: {
        SAVE_TOKEN: (state, token) => state.token = token,
        SAVE_TOKEN_TYPE: (state, token_type) => state.token_type = token_type,
        SAVE_EXPIRES_IN: (state, expires_in) => state.expires_in = expires_in * 1000,
        SAVE_REFRESH_TOKEN: (state, refresh_token) => state.refresh_token = refresh_token,
        SAVE_AUTHORIZATION_DATE: (state) => state.authorization_date = Number(Date.now().toString()),
        SAVE_GOOGLE_AUTH: (state, boolean) => state.google_auth = boolean,
        SAVE_APPLE_AUTH: (state, boolean) => state.apple_auth = boolean,
        CLEAR_AUTH: (state) => Object.assign(state, getDefaultState())
    }
}
