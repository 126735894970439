<template>
    <w-alert :show="!$store.state.alerts.cookie_banner_accepted" class="cookie_banner">
        <div class="cookie_banner__layout">
            <div class="cookie_banner__text"
                 v-html="$t('cookies_accept_title')"
                 @click="$router.push({ name: 'PrivacyPage' })"
            />
            <w-button white small
                      :transparent_grey="$store.getters.MOBILE"
                      class="cookie_banner__btn"
                      @click="acceptCookie"
            >
                {{ $t('action_fine') }}
            </w-button>
        </div>
    </w-alert>
</template>

<script>
import Cookies from "js-cookie";

export default {
    name: "cookie_banner",
    methods: {
        acceptCookie() {
            Cookies.set('cookie_confirmed', '1', {secure: true, expires: Date.now() + Number(1577e+10)});
            this.$store.commit("CHANGE_COOKIE_BANNER_ACCEPTED", true);
        }
    },
}
</script>

<style scoped lang="scss">
.cookie_banner {
    &__layout {
        display: flex;
        align-items: center;
    }

    &__text {
        font-size: 16px;
        line-height: 20px;

        &:deep span {
            cursor: pointer;
            color: var(--w_color_green);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__btn {
        padding: 6px 12px;
        margin-left: 16px;
        color: var(--w_color_dark_fixed) !important;
    }
}

@media (max-width: 1440px) and (min-width: 981px) {
    .cookie_banner {
        &__text {
            font-size: 11px;
            line-height: 14px;
        }

        &__btn {
            font-size: 11px !important;
            margin-left: 11px;
        }
    }
}

@media screen and (max-width: 980px) {
    .cookie_banner {
        &__layout {
            flex-direction: column;
            align-items: flex-start;
        }

        &__btn {
            width: 100%;
            margin-left: 0;
            margin-top: 14px;
            opacity: 0.9;
            color: var(--w_color_white) !important;
        }
    }

    [data-theme='dark'] {
        .cookie_banner__btn {
            box-shadow: inset 0 0 0 1px var(--w_color_grey6) !important;
        }
    }
}

[data-theme='dark'] {
    .cookie_banner:deep .alert_content {
        background-color: var(--w_color_grey5) !important;
    }
}
</style>