import axios from "axios";

const getDefaultState = () => {
    return {
        sections_list: [],
        sections_list_limit: 20,
        sections_list_search_name: "",
        sections_list_all_loaded: false,
        new_section_data: null,
        delete_section_data: null,
        open_sections_ids: [],
        sections_mode: false,
    }
}
export default {
    state: () => getDefaultState(),
    actions: {
        GET_SECTIONS_LIST({state, commit, getters}, section_name) {
            return new Promise((resolve, reject) => {
                let update = false,
                    params = {
                        'limit': state.sections_list_limit,
                        'project_uuid': getters.SELECTED_PROJECT.uuid
                    };

                if (section_name) {
                    params['name'] = section_name;
                    if (section_name !== state.sections_list_search_name) update = true;
                    else if (state.sections_list.length) params['offset'] = state.sections_list.filter(s => s.id !== 'without_section').length;
                } else if (state.sections_list.length) {
                    params['offset'] = state.sections_list.filter(s => s.id !== 'without_section').length;
                }

                if (state.sections_list_all_loaded && !section_name) resolve()
                else {
                    axios
                        .get(this.$api_url_locals + "/section/list", {params})
                        .then(response => {
                            if (section_name) resolve(response.data.data.sections);
                            else {
                                let list = response.data.data.sections;
                                if (list.length < state.sections_list_limit) commit("SAVE_SECTIONS_LIST_ALL_LOADED", true);
                                if (update) commit("SAVE_SECTION_LIST_SEARCH_NAME", section_name);
                                commit("SAVE_SECTIONS_LIST", {"list": list, "update": update});
                                resolve(response.data.data.sections);
                            }
                        })
                        .catch(err => reject(err))
                }
            })
        },
        CREATE_NEW_SECTION({commit, getters}, section_name) {
            return new Promise((resolve, reject) => {
                axios
                    .post(this.$api_url_locals + "/section", {
                        'name': section_name,
                        'project_uuid': getters.SELECTED_PROJECT.uuid
                    })
                    .then(response => {
                        commit("SAVE_NEW_SECTION", response.data.data.section);
                        resolve(response.data.data.section);
                    })
                    .catch(err => {
                        reject(err.code)
                    })
            })
        },
        EDIT_SECTION({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put(this.$api_url_locals + "/section/" + payload.id, {
                        'name': payload.name
                    })
                    .then(response => {
                        commit("SAVE_EDITED_SECTION", response.data.data.section);
                        resolve(response.data.data.section);
                    })
                    .catch(err => {
                        reject(err.code)
                    })
            })
        },
        async DELETE_SECTION({commit, rootState}, payload) {
            await axios
                .delete(this.$api_url_locals + "/section/" + payload.id, {
                    data: {'is_keys_deletion': payload.is_keys_deletion}
                })
                .then(() => {
                    commit("DELETE_SECTION_FROM_LIST", payload.id);
                    commit("SAVE_OPEN_SECTIONS_IDS", {id: payload.id, type: 'delete'})

                    if (rootState.keys.search_sections_ids.length) {
                        commit("SAVE_SEARCH_SECTIONS_IDS", rootState.keys.search_sections_ids.filter(id => id !== payload.id));
                    }

                    commit("UPDATE_KEYS");
                })
        },
    },
    mutations: {
        SAVE_SECTIONS_LIST(state, payload) {
            if (payload.update) state.sections_list = payload.list
            else if (!state.sections_list.length) state.sections_list = payload.list
            else state.sections_list = state.sections_list.concat(payload.list)

            let without_section_index = state.sections_list.findIndex(s => s.id === 'without_section')
            if (~without_section_index) {
                const without_section = state.sections_list[without_section_index]
                state.sections_list.splice(without_section_index, 1)
                state.sections_list.push(without_section)
            }

            let sections = state.sections_list.reduce((acc, section) => {
                if (acc.map[section.id]) return acc;
                acc.map[section.id] = true;
                acc.sections.push(section);
                return acc;
            }, {map: {}, sections: []});

            state.sections_list = sections.sections;
        },
        SAVE_NEW_SECTION(state, section) {
            state.sections_list.push(section)
        },
        SAVE_EDITED_SECTION(state, section) {
            let index = state.sections_list.findIndex(s => s.id === section.id);
            if (~index) state.sections_list.splice(index, 1, section);
        },
        DELETE_SECTION_FROM_LIST(state, section_id) {
            let index = state.sections_list.findIndex(s => s.id === section_id);
            if (~index) state.sections_list.splice(index, 1);
        },
        SAVE_SECTION_LIST_SEARCH_NAME(state, name) {
            state.sections_list_search_name = name
        },
        SAVE_SECTIONS_LIST_ALL_LOADED(state, boolean) {
            state.sections_list_all_loaded = boolean
        },
        SAVE_NEW_SECTION_DATA(state, data) {
            state.new_section_data = data
        },
        SAVE_DELETE_SECTION_DATA(state, data) {
            state.delete_section_data = data
        },
        CHANGE_SECTIONS_MODE(state, boolean) {
            state.sections_mode = boolean
        },
        SAVE_NEW_KEY_IN_SECTION(state, payload) {
            if (payload.sections_ids.length) {
                payload.sections_ids.forEach(id => {
                    let selection = state.sections_list.find(s => s.id === id)
                    if (selection) {
                        if (selection.keys) selection.keys.push(payload.key)
                        else selection['keys'] = [payload.key]
                    }
                })
            } else {
                let without_section = state.sections_list.find(s => s.id === 'without_section')
                if (without_section) {
                    if (without_section['keys']) without_section['keys'].push(payload.key)
                    else without_section['keys'] = [payload.key]
                } else {
                    state.sections_list.push({
                        'id': 'without_section',
                        'name': 'without_section_name',
                        'keys': [payload.key],
                        'all_loaded': true
                    });
                }
            }
        },
        UPDATE_KEY_IN_SECTION(state, key) {
            state.sections_list.forEach(s => {
                if (s.keys) {
                    let section_key = s.keys.find(k => k.id === key.id);
                    if (section_key) Object.assign(section_key, key);
                }
            })
        },
        DELETE_KEY_FROM_SECTIONS(state, key_id) {
            state.sections_list.forEach(s => {
                if (s.keys) {
                    let index = s.keys.findIndex(k => k.id === key_id);
                    if (~index) s.keys.splice(index, 1);
                }
            })
        },
        SAVE_OPEN_SECTIONS_IDS(state, payload) {
            if (!payload) {
                state.open_sections_ids = []
            } else if (payload.type === 'delete') {
                state.open_sections_ids = state.open_sections_ids.filter(id => id !== payload.id)
            } else {
                if (Array.isArray(payload.id)) state.open_sections_ids = payload.id
                else state.open_sections_ids.push(payload.id)
            }
        },
        SAVE_KEYS_IN_SECTION(state, payload) {
            let section = state.sections_list.find(s => s.id === payload.section_id);
            if (section) {
                if (payload.download) section['keys'] = section.keys.concat(payload.keys);
                else section['keys'] = payload.keys;
                section['all_loaded'] = payload.all_loaded;
            } else {
                let without_section = state.sections_list.find(s => s.id === 'without_section');
                if (without_section) {
                    if (payload.download) without_section['keys'] = without_section.keys.concat(payload.keys);
                    else without_section['keys'] = payload.keys;
                    without_section['all_loaded'] = payload.all_loaded;
                } else if (payload.keys.length > 0) {
                    state.sections_list.push({
                        'id': 'without_section',
                        'name': 'without_section_name',
                        'keys': payload.keys,
                        'all_loaded': payload.all_loaded
                    });
                }
            }
        },
        CLEAR_SECTIONS(state) {
            Object.assign(state, getDefaultState())
        }
    }
}