import * as CodeMirror from "codemirror";
import "codemirror/mode/xml/xml.js";
import "codemirror/addon/mode/overlay.js";
import "codemirror/addon/display/placeholder.js";
import "codemirror/addon/runmode/runmode.js";
import {single_tags, tagNameRegExp} from "@/assets/js/parsing_settings.js";

const regex1 = '\\[%[a-zA-Z0-9:._$]{1,}]';
const regex2 = '{{1,2}[a-zA-Z0-9:._]{1,}}{1,2}';
const regex3 = '%[a-zA-Z0-9_.$@]{1,}%?';
const RE = new RegExp(`(${regex1})|(${regex2})|(${regex3})`, 'i');
const new_line = /(\\n)|(\\r)|(\\t)/;

let syntax_error = false,
    open_tags = 0;

CodeMirror.defineMode("localit", function (config, parserConfig) {
    const localitModeOverlay = {
        token: function (stream) {
            let spaces = 0;
            let spacePeek= stream.peek() === ' ';

            if (stream.sol()) {
                open_tags = 0;
                syntax_error = false;
            }

            if (stream.match(RE)) {
                return "placeholder";
            } else if (stream.match(new_line)) {
                return "linebreak";
            } else if (stream.match(/<\/?[a-z]*(\s)?[a-z"'=\s:/.-]*>/, false)) {
                let tag = stream.match(/<\/?[a-z]*(\s)?[a-z"'=\s:/.-]*>/)
                let is_closed = tag[0].startsWith('</');
                let tag_name = tag[0].match(tagNameRegExp)?.[1];
                let is_alone = tag_name && (tag[0].endsWith('/>') || single_tags.includes(tag_name));

                if (!is_alone && !syntax_error) {
                    open_tags += is_closed ? -1 : 1;
                    if (!syntax_error && (open_tags < 0)) syntax_error = true;
                }

                return "tag" + (syntax_error ? " error" : "");
            } else if (spacePeek) {
                while (spacePeek && spaces < 20) {
                    ++spaces;

                    stream.next();
                    spacePeek = stream.peek() === ' ';
                }
                if (spaces === 1) return ''
                else return 'space space-' + spaces;
            }

            while (
                (stream.next() != null) &&
                !stream.match(/<\/?[a-z]*(\s)?[a-z"'=\s:/.-]*>/, false) &&
                !stream.match(RE, false) &&
                !stream.match(new_line, false) &&
                !stream.match(/^\s{2,}/, false) &&
                !stream.match(/\s{2,}$/, false)
                // eslint-disable-next-line no-empty
            ) {}

            return null;
        }
    };
    return CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop), localitModeOverlay);
    // return CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop || "text/html"), localitModeOverlay);
});

CodeMirror.defineMIME("application/localit", "localit");