import axios from "axios";
import Cookies from "js-cookie";

const getDefaultState = () => {
    return {
        languages: [],
        last_languages: [],
        languages_x_key: "",
        languages_localize: ""
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_LANGUAGES({commit, state, getters}) {
            let local = getters.LOCALE.dictionary_code || 'eng',
                headers = {};

            if (!state.languages.length || !Cookies.get('langs_x_key') || local !== state.languages_localize) {
                if (state.languages_x_key && state.languages.length && local === state.languages_localize) headers["X-modified-key"] = state.languages_x_key

                await axios
                    .get(`${this.$api_url_locals}/language/list`, {headers})
                    // .get(`${this.$api_url}/languages/get/all-data?platform=WEB`, {headers})
                    .then(async response => {
                        if (response) {
                            let languages = response.data.data.languages.sort((a, b) => a['name'].localeCompare(b['name'], local));

                            await commit("SAVE_LANGUAGES", languages);
                            await commit("SAVE_LANGUAGES_X_KEY", response.data.modified_key);

                            if (state.last_languages.length) {
                                for (let i = 0; i < state.last_languages.length; i++) {
                                    state.last_languages[i] = languages.find(lang => lang.code === state.last_languages[i]?.code)
                                }
                            }

                            state.languages_localize = local;
                        }

                        Cookies.set('langs_x_key', state.languages_x_key, {
                            secure: true,
                            'max-age': '86400'
                        });
                    })
            }
        },
        async PUT_LANGUAGE_CONFIG({dispatch, commit, rootState}, payload) {
            await axios
                .put(`${this.$api_url_locals}/project/language/${payload.lang_id}`, payload.data)
                .then(resp => {
                    let project = rootState.projects.projects_list.find(p => p.uuid === payload.project_uuid);

                    if (project) {
                        let lang = project.project_languages.find(l => l.id === payload.lang_id);
                        if (lang) Object.assign(lang, resp.data.data.project_language);
                    }

                    commit("UPDATE_KEYS");
                })
                .catch(err => {
                    dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    throw Error(err.code);
                })
        }
    },
    mutations: {
        SAVE_LANGUAGES: (state, languages) => state.languages = languages,
        SAVE_LANGUAGES_X_KEY: (state, key) => state.languages_x_key = key,
        CLEAR_LANGUAGES: (state) => Object.assign(state, getDefaultState())
    }
}
