module.exports = [
    {
        code: 'en',
        base: '',
        route_code: '',
        dictionary_code: 'eng',
        translations: '/src/locales/en.json'
    },
    {
        code: 'es',
        base: '/es',
        route_code: 'es',
        dictionary_code: 'spa',
        translations: '/src/locales/es.json'
    },
    {
        code: 'de',
        base: '/de',
        route_code: 'de',
        dictionary_code: 'ger',
        translations: '/src/locales/de.json'
    },
    {
        code: 'fr',
        base: '/fr',
        route_code: 'fr',
        dictionary_code: 'fre',
        translations: '/src/locales/fr.json'
    },
    {
        code: 'ru',
        base: '/ru',
        route_code: 'ru',
        dictionary_code: 'rus',
        translations: '/src/locales/ru.json'
    }
]
