<template>
    <div class="w_popup_mask"
         :class="{'dark_mask': image}"
         tabindex="0"
         @mousedown.self="clickClosePopup"
         @click.self="mobileClosePopup"
         @keydown.esc.stop="closePopup"
         @keydown.enter.stop="$emit('keyEnter')"
         @keydown.stop="$emit('keydown', $event)"
    >
        <div class="w_popup_content"
             ref="popup"
             :class="{audio, addition_popup, attributes_popup, transparent, image, overflow}"
        >
            <slot>popup</slot>
            <div v-if="close_in"
                 class="w_popup_close icon"
                 :class="{'ease': image}"
                 @click.stop="closePopup"
            >
                <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 2.5L9.5 9.5M2.50001 9.5L6.00001 6L9.50001 2.5" stroke="#949EAE" stroke-width="1" stroke-linecap="round"/>
                </svg>
            </div>
        </div>
        <div v-if="close_out"
             class="w_popup_close icon"
             :class="{'ease': image}"
             @click.stop="closePopup"
        >
            <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 2.5L9.5 9.5M2.50001 9.5L6.00001 6L9.50001 2.5" stroke="#949EAE" stroke-width="1" stroke-linecap="round"/>
            </svg>
        </div>
    </div>
</template>

<script>
    import CANCEL_TOKENS from "../../assets/js/cancel_tokens";
    import {mapGetters} from "vuex";

    export default {
        name: "w_popup",
        props: {
            addition_popup: {
                type: Boolean,
                default: false
            },
            attributes_popup: {
                type: Boolean,
                default: false
            },
            audio: {
                type: Boolean,
                default: false
            },
            close_in: {
                type: Boolean,
                default: false
            },
            close_out: {
                type: Boolean,
                default: false
            },
            confirm: {
                type: Boolean,
                default: false
            },
            image: {
                type: Boolean,
                default: false
            },
            overflow: {
                type: Boolean,
                default: false
            },
            transparent: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                eventStartPosition: null,
                limit: null,
                popupNode: null,
                hiddenMenuTimeout: null,
                show_menu: false,
                scale_value: 1
            }
        },
        computed: {
            ...mapGetters(['MOBILE'])
        },
        watch: {
            $route: 'closePopup'
        },
        mounted() {
            this.$el.focus()
            if (this.MOBILE) {
                this.popupNode = this.$refs['popup']
                if (this.popupNode) this.limit = this.popupNode.clientHeight < 150 ? 50 : (this.popupNode.clientHeight / 2)
            }
        },
        methods: {
            clickClosePopup(e) {
                if (!this.MOBILE) {
                    e.stopPropagation();
                    e.preventDefault();
                    if (this.confirm) return false
                    else this.closePopup()
                }
            },
            closePopup() {
                this.$emit('closePopup')
                if (this.confirm) {
                    CANCEL_TOKENS.forEach(source => source.token?.cancel('Request canceled from popup'))
                }
            },
            mobileClosePopup(e) {
                if (this.MOBILE) {
                    e.stopPropagation();
                    e.preventDefault();
                    if (this.confirm) return false
                    else this.closePopup()
                }
            }
        },
        beforeUnmount() {
            if (this.confirm) {
                CANCEL_TOKENS.forEach(source => source.token?.cancel('Request canceled from popup'))
            }
        },
        emits: ['closePopup', 'copyImage', 'downloadImage', 'replyImage', 'keyEnter', 'keydown']
    }
</script>

<style lang="scss" scoped>
    .w_popup {
        &_mask {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: fixed;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3000;
            background-color: rgba(0, 0, 0, 0.7);

            &.dark_mask {
                background-color: rgba(0, 0, 0, 0.8);
            }
        }

        &_close {
            position: absolute;
            top: 28px;
            right: 28px;
            width: 32px;
            height: 32px;
            cursor: pointer;
            border-radius: 50%;
            z-index: 200;
            background-color: var(--w_color_grey2);

            svg {
                width: 20px;
                height: 20px;

                &:deep(path) {
                    stroke: var(--w_color_grey6);
                }
            }

            &.ease {
                width: 40px;
                height: 40px;
                background-color: transparent !important;

                svg {
                    width: 24px;
                    height: 24px;

                    &:deep(path) {
                        stroke: var(--w_color_white);
                    }
                }
            }
        }

        &_content {
            min-height: 14vh;
            min-width: 20vh;
            border-radius: 20px;
            position: fixed;
            z-index: 130;
            background-color: var(--w_color_white);
            box-shadow: 0 -8px 16px rgba(0, 0, 0, 0.06);

            &.audio {
                padding: 44px 160px;
            }

            &.addition_popup {
                padding: 32px 135px;
            }

            &.attributes_popup {
                padding: 0 135px;
            }

            &.transparent {
                background-color: transparent !important;
            }

            &.overflow {
                overflow: hidden;
            }

            &.image {
                border-radius: 8px;
                overflow: hidden;
                display: flex;
                transition: transform 300ms ease;
                min-height: auto;
                min-width: auto;
                pointer-events: none;
            }
        }
    }

    @media (max-width: 1440px) and (min-width: 981px) {
        .w_popup {
            &_close {
                top: 20px;
                right: 20px;
                width: 22px;
                height: 22px;

                svg {
                    width: 14px;
                    height: 14px;
                }

                &.ease {
                    width: 28px;
                    height: 28px;

                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            &_content {
                border-radius: 14px;

                &.audio {
                    padding: 30px 110px;
                }

                &.addition_popup {
                    padding: 22px 96px;
                }

                &.attributes_popup {
                    padding: 0 96px;
                }
            }
        }
    }

    @media screen and (max-width: 980px) {
        .w_popup {
            &_close {
                top: 18px;
                right: 18px;
                background-color: transparent;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            &_mask {
                background-color: rgba(0, 0, 0, 0.4);
            }

            &_content {
                width: 100%;
                max-width: 100vw;
                min-height: 100vh;
                border-radius: 0;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                will-change: transform;
                transition: transform 50ms linear;
                box-shadow: none;
            }
        }

        .modal {
            &-enter-from, &-leave-to {
                opacity: 1;
                background-color: rgba(0, 0, 0, 0) !important;

                .w_popup_content {
                    transform: translateX(100%) !important;
                }
            }

            &-enter-active, &-leave-active {
                transition: background-color 400ms ease;

                .w_popup_content {
                    transition: transform 400ms ease;
                }
            }
        }

        @supports (-webkit-touch-callout: none) {
            @media (display-mode: standalone) {
                .w_popup {
                    &_content {
                        padding-bottom: 40px !important;
                    }

                    &_close {
                        top: calc(var(--standalone_top_indent) + 18px) !important;
                    }
                }
            }
        }
    }

    [data-theme='dark'] {
        .w_popup {
            &_mask {
                background-color: rgba(0, 0, 0, 0.6);

                &.dark_mask {
                    background-color: rgba(0, 0, 0, 0.75);
                }
            }

            &_close {
                background-color: var(--w_color_grey7);
            }

            &_content {
                background-color: var(--w_color_grey2);
                box-shadow: 0 -8px 20px rgba(0, 0, 0, 0.5);
            }
        }

        @media screen and (max-width: 980px) {
            .w_popup_content {
                background-color: var(--w_color_grey7);
            }
        }
    }
</style>
