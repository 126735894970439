import axios from "axios";

const getDefaultState = () => {
    return {
        active_payment_order: sessionStorage['active_payment_order'] || '',
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        GET_STRIPE_SESSION_ID({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${this.$api_url_locals}/create-checkout-session`, payload)
                    .then(resp => resolve(resp.data.data.client_secret))
                    .catch(err => reject(err))

                commit
            })
        },
        CHECK_ORDER_PAYMENT_STATUS({commit}, sessionId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${this.$api_url_locals}/session-status?session_id=${sessionId}`)
                    .then(async resp => {
                        commit("UPDATE_ORDERS_KEY")
                        resolve(resp.data.status)
                    })
                    .catch(err => reject(err))
            })
        },
        CREATE_CUSTOMER_SESSION({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${this.$api_url_locals}/create-customer-session`, payload)
                    .then(resp => resolve(resp.data.data))
                    .catch(err => reject(err))

                commit
            })
        },
    },
    mutations: {
        CHANGE_ACTIVE_PAYMENT_ORDER: (state, order_number) => {
            if (order_number) {
                state.active_payment_order = order_number;
                sessionStorage.setItem('active_payment_order', order_number);
            } else {
                state.active_payment_order = '';
                sessionStorage.removeItem('active_payment_order');
            }
        },
        CLEAR_PAYMENTS: (state) => {
            Object.assign(state, getDefaultState());
            sessionStorage.removeItem('active_payment_order');
        }
    },
}
