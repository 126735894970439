import Cookies from "js-cookie";

const getDefaultState = () => {
    return {
        alert_system: null,
        alert_auth: null,
        alert_dictionary: null,
        alert_new_points: null,
        alert_addition: null,
        alert_favorite: null,
        alert_detail: null,
        alert_complain: null,
        alert_error: null,
        alert_need_action: null,
        alert_learning: null,

        alert_system_timeout: null,
        alert_auth_timeout: null,
        alert_dictionary_timeout: null,
        alert_new_points_timeout: null,
        alert_addition_timeout: null,
        alert_favorite_timeout: null,
        alert_detail_timeout: null,
        alert_complain_timeout: null,
        alert_error_timeout: null,
        alert_need_action_timeout: null,
        alert_learning_timeout: null,

        cookie_banner_accepted: Boolean(Cookies.get('cookie_confirmed')),
    }
}

export default {
    state: () => getDefaultState(),
    mutations: {
        ALERT_SHOW: (state, {name, content, duration = 0}) => {
            let delay = 0,
                alert_name = 'alert_' + name,
                alert_timeout = alert_name + '_timeout';

            if (state[alert_name]) {
                state[alert_name] = null;
                clearTimeout(state[alert_timeout]);
                delay = 300;
            }

            function clearState() {
                state.alert_system = null;
                state.alert_auth = null;
                state.alert_dictionary = null;
                state.alert_new_points = null;
                state.alert_addition = null;
                state.alert_favorite = null;
                state.alert_detail = null;
                state.alert_complain = null;
                state.alert_error = null;
                state.alert_need_action = null;
            }

            setTimeout(() => {
                clearState();
                state[alert_timeout] = setTimeout(() => state[alert_name] = null, 5000);
                state[alert_name] = content || true;
            }, delay + duration);
        },
        CLOSE_ALERT: (state, name) => {
            clearTimeout(state['alert_' + name + '_timeout']);
            state['alert_' + name] = null;
        },
        CHANGE_COOKIE_BANNER_ACCEPTED(state, bool) {
            state.cookie_banner_accepted = bool
        },
        CLOSE_ALL_ALERTS(state) {
            for (let s in state) {
                if (s.includes('_timeout')) clearTimeout(state[s])
            }
            Object.assign(state, getDefaultState());
        }
    }
}
