<template>
    <div class="w_input"
         :class="{'active': inputFocus, invalid}"
         @click="focusInput"
         @focusin="changeFocusIn(true)"
         @focusout="changeFocusIn(false)"
    >
        <label class="w_input__label" :class="{'active': inputFocus || modelValue || active}">
            <div class="w_input__label-name">
                <w-flag v-if="mini_flag" class="w_input__label-name-flag" :code="mini_flag"/>
                <span class="w_input__label-name-text">{{ placeholder }}</span>
            </div>
            <input class="w_input__label-text"
                   :class="{'semi-bold': !regular}"
                   ref="input"
                   :name="type"
                   :value="modelValue"
                   :maxlength="maxlength"
                   :minlength="minlength"
                   :max="max"
                   :min="min"
                   :type="type"
                   :readonly="readonly"
                   :spellcheck="spellcheck"
                   :placeholder="input_placeholder"
                   :autocomplete="autocomplete"
                   @input="inputText($event.target.value)"
                   @change="$emit('change', $event.target.value)"
                   @contextmenu="contextAction"
                   @focus="setAutocomplete"
                   @keydown="validateTextForInput"
                   @keyup="keyupAction"
                   @paste="$emit('paste', $event.clipboardData.getData('Text'))"
                   @keydown.enter="handleEnter"
                   @click.prevent="$emit('click')"
            >
        </label>
        <div class="w_input__detail number" v-if="type === 'number'">
            <div class="w_input__detail-flag pointer first" @click="inputText(Number(modelValue) - 1)">
                <w-svg-icon class="w_input__detail-flag--icon number" :class="{'active': Number(modelValue) > min}"
                            folder-name="main" icon-name="iconMinus"/>
            </div>
            <div class="w_input__detail-flag pointer" @click="inputText(Number(modelValue) + 1)">
                <w-svg-icon class="w_input__detail-flag--icon number" :class="{'active': Number(modelValue) < max}"
                            folder-name="main" icon-name="iconPlus"/>
            </div>
        </div>
        <div class="w_input__detail" v-else-if="detail">
            <w-flag v-if="flag" class="w_input__detail-flag" :code="flag"/>
            <slot v-else name="detail"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "w_input",
    props: {
        active: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: 'on'
        },
        autofocus: {
            type: [String, Number],
            default: 0
        },
        detail: {
            type: Boolean,
            default: false
        },
        flag: String,
        input_placeholder: {
            type: String,
            default: ''
        },
        invalid: {
            type: Boolean,
            default: false
        },
        max: {
            type: Number
        },
        maxlength: {
            type: [Number, String],
            default: 255
        },
        min: {
            type: Number
        },
        mini_flag: String,
        minlength: {
            type: [Number, String],
            default: 0
        },
        modelValue: [String, Number],
        placeholder: {
            type: String,
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false
        },
        regexp: RegExp,
        regular: {
            type: Boolean,
            default: false
        },
        spellcheck: {
            type: Boolean,
            default: false
        },
        tabindex: {
            type: [Number, String]
        },
        type: {
            type: String,
            default: 'text'
        }
    },
    data() {
        return {
            inputFocus: false
        }
    },
    mounted() {
        if (!this.$store.getters.MOBILE) {
            let duration = Number(this.autofocus)
            if (duration > 0) setTimeout(() => this.focusInput(), duration)

            if (this.tabindex) this.$refs['input'].setAttribute('tabindex', this.tabindex)
        }
    },
    methods: {
        changeFocusIn(bool) {
            this.inputFocus = bool;
            this.$emit('changeFocus', bool);
        },
        contextAction(e) {
            if (this.spellcheck) e.stopPropagation()
        },
        focusInput() {
            this.$el.querySelector('input')?.focus()
        },
        handleEnter() {
            if (this.modelValue) this.$emit('enter')
        },
        async inputText(text) {
            let result = text
            if (this.regexp) result = this.validateText(result);
            else if (this.type === 'number') {
                if (!result) result = ""
                else if (this.min > Number(result)) result = this.min
                else if (this.max < Number(result)) result = this.max
            }
            this.$emit('input')
            this.$emit('update:modelValue', result);
        },
        keyupAction(e) {
            this.$emit('keyup', e);
        },
        setAutocomplete() {
            this.$refs['input']?.setAttribute('autocomplete', 'off')
        },
        validateText(text) {
            let result = text;
            if (this.regexp && text.match(this.regexp)) result = text.replaceAll(this.regexp, '');
            return result;
        },
        validateTextForInput(e) {
            if (this.regexp && e.key.match(this.regexp) && !e.ctrlKey) {
                e.preventDefault();
                e.stopPropagation();
            } else {
                this.$emit('keydown', e);
            }
        }
    },
    emits: ['update:modelValue', 'input', 'paste', 'click', 'change', 'enter', 'keydown', 'keyup', 'changeFocus']
}
</script>

<style lang="scss" scoped>
.w_input {
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: text;
    box-shadow: var(--l_input_start_shadow);
    background-color: var(--l_input_background);
    color: var(--w_color_dark);
    transition: box-shadow 200ms ease;

    &:hover {
        box-shadow: var(--l_input_hover_shadow) !important;
    }

    &.active {
        box-shadow: var(--l_input_active_shadow) !important;
    }

    &.success {
        box-shadow: var(--l_input_success_shadow) !important;
        background: var(--l_input_success_background) !important;
    }

    &.invalid {
        box-shadow: var(--l_input_invalid_shadow) !important;
        background: var(--l_input_invalid_background) !important;
    }

    &__detail {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 12px;
        cursor: default;

        &.number {
            width: 72px;
        }

        &-flag {
            width: 24px;
            height: 24px;
            min-width: 24px !important;

            &.first {
                margin-right: 12px;
            }

            &--icon {
                width: 100%;
                height: 100%;

                &:deep(path) {
                    fill: var(--w_color_grey4);
                }

                &.active:deep(path) {
                    fill: var(--w_color_grey6) !important;
                }
            }
        }
    }

    &__label {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 24px 16px 6px;
        text-align: left;
        color: inherit;
        transition: padding 300ms ease;
        position: relative;
        cursor: inherit;

        &-name, &-text {
            font-size: 16px;
            line-height: 20px;
            padding: 0;
            background-color: transparent;
        }

        &-name {
            position: absolute;
            top: calc(50% - 10px);
            left: 16px;
            transition: transform 300ms ease;
            pointer-events: none;
            transform-origin: top left;
            display: flex;
            align-items: center;
            user-select: none;

            &-flag {
                width: 24px;
                height: 24px;
                margin-right: 6px;
                border: 1px solid var(--w_color_grey7);
                box-sizing: border-box !important;
            }

            &-text {
                color: var(--w_color_grey6);
            }
        }

        &-text {
            background-color: transparent;
            color: inherit;
            transform: translateY(-6px);
            transition: transform 300ms ease;
            caret-color: var(--w_color_green);
        }

        &.active {
            .w_input__label-name {
                transform: scale(0.8) translateY(-12px);
            }

            .w_input__label-text {
                transform: none;
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 981px) {
    .w_input {
        border-radius: 11px;

        &__detail {
            height: 40px;
            width: 40px;
            padding-right: 8px;

            &.number {
                width: 48px;
            }

            &-flag {
                width: 16px;
                height: 16px;
                min-width: 16px !important;

                &.first {
                    margin-right: 8px;
                }
            }
        }

        &__label {
            height: 40px;
            padding: 16px 11px 4px;

            &-name, &-text {
                font-size: 11px;
                line-height: 14px;
            }

            &-name {
                top: calc(50% - 7px);
                left: 11px;

                &-flag {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }

            &-text {
                transform: translateY(-4px);
            }

            &.active {
                .w_input__label-name {
                    transform: scale(0.8) translateY(-8px);
                }
            }
        }
    }
}

[data-theme='dark'] {
    .w_input__detail-flag--icon:deep(path) {
        fill: var(--w_color_grey5);
    }
}
</style>
