const getDefaultState = () => {
    return {
        loading_app: true,
        loading_auth: false,
        loading_google_translate: false,
        loading_addition: false,
        loading_delete: false,
        loading_internet_connection: false,
        loading_refresh_token: false,
        loading_projects_list: false,
        loading_projects_info: false,
        loading_translations_keys_list: false,
    }
}

export default {
    state: () => getDefaultState(),
    mutations: {
        CHANGE_LOADING_APP_STATUS: (state, boolean) => state.loading_app = boolean,
        CHANGE_LOADING_AUTH_STATUS: (state, boolean) => state.loading_auth = boolean,
        CHANGE_LOADING_GOOGLE_TRANSLATE_STATUS: (state, boolean) => state.loading_google_translate = boolean,
        CHANGE_LOADING_ADDITION_STATUS: (state, boolean) => state.loading_addition = boolean,
        CHANGE_LOADING_DELETE_STATUS: (state, boolean) => state.loading_delete = boolean,
        CHANGE_LOADING_INTERNET_CONNECTION_STATUS: (state, boolean) => state.loading_internet_connection = boolean,
        CHANGE_LOADING_REFRESH_TOKEN_STATUS: (state, boolean) => state.loading_refresh_token = boolean,
        CHANGE_LOADING_PROJECTS_INFO: (state, boolean) => state.loading_projects_info = boolean,
        CHANGE_LOADING_PROJECTS_LIST: (state, boolean) => state.loading_projects_list = boolean,
        CHANGE_TRANSLATIONS_KEYS_LIST_LOADING: (state, boolean) => state.loading_translations_keys_list = boolean,
    },
}
