<template>
  <div class="l_editor"
       :class="{'scrollable': translation_mode}"
       @click="setFocus"
       @touchend="setFocus"
  >
    <textarea
      :value="modelValue"
      id="codemirror_editor"
      rows="1"
      :placeholder="placeholder"
    />
    <transition name="bounce-scale">
      <div v-if="syntax_error" class="l_editor-error notice error-notice notice-bottom">
        {{ $t('tags_placed_incorrectly') }}
      </div>
    </transition>
  </div>
</template>

<script>
import * as CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "@/assets/js/localit_mode.js";
import {getPlaceholderName, single_tags, tagNameRegExp} from "@/assets/js/parsing_settings.js";

export default {
  name: "translation_editor",
  props: {
    autofocus: {
      type: [Number, String]
    },
    code_mode: {
      type: Boolean,
      default: false
    },
    modelValue: String,
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    translation_mode: Boolean
  },
  data() {
    return {
      syntax_error: false
    }
  },
  watch: {
    code_mode: 'updateView'
  },
  mounted() {
    let autofocus = this.autofocus !== 'off';

    this.cm = CodeMirror.fromTextArea(this.$el.firstChild, {
      viewportMargin: 50,
      autofocus: autofocus,
      lineWrapping: true,
      workDelay: 0,
      reHighlightOnChange: true,
      showCursorWhenSelecting: true,
      maxHighlightDelay: 100,
      maxHighlightLength: 50000,
      flattenSpans: false,
      overlayMode: true,
      spellcheck: true,
      autocorrect: true,
      readOnly: this.readonly ? 'nocursor' : false,
      mode: 'localit',
      extraKeys: {
        Tab: function (cm) {
          let spaces = Array(cm.getOption("indentUnit") + 3).join(" ");
          cm.replaceSelection(spaces);
        }
      }
    })

    window.CodeMirror = this.cm;
    window.CodeMirrorLast = this.cm;

    this.updateView();

    this.cm.on("change", (i) => {
      const p = i.getValue();
      this.$emit("update:modelValue", p || "");
      this.$emit("input", p || "");
      this.updateView(p);
    })

    this.cm.on("blur", () => {
      this.$emit("blur");
    })

    this.setCursor(autofocus);
  },
  methods: {
    setCursor(autofocus) {
      this.cm.execCommand('goDocEnd');
      if (autofocus) this.cm.focus();
    },
    setFocus() {
      window.CodeMirrorLast = this.cm;
    },
    setValue(text) {
      this.cm.doc.setValue(text);
    },
    updateView() {
      if (this.code_mode) {
        this.cm.doc.getAllMarks().forEach(marker => marker.clear());

        clearTimeout(this.error_timeout);
        this.error_timeout = setTimeout(() => this.syntax_error = !!this.$el.querySelector('span.cm-tag.cm-error'), 200);
      } else {
        let lines_count = this.cm.display.view.length, i = 1, ti_o = 1, ti_c = 1, syntax_error = false, open_tags = 0;

        for (let line = 0; line < lines_count; line++) {
          let tokens = this.cm.getLineTokens(line);
          tokens.filter(t => t.type).forEach(t => {
            if (t.type.includes('placeholder')) {
              let name = getPlaceholderName(t.string);
              let a = document.createElement('span');

              a.className = 'cm-placeholder';
              a.setAttribute('data-placeholder', t.string);
              a.setAttribute('data-index', String(i));
              a.setAttribute('data-name', name);
              a.innerText = `  ${String(i)}${name ? (':' + name) : ''}  `;

              this.cm.markText({line: line, ch: t.start}, {line: line, ch: t.end}, {
                className: "",
                atomic: true,
                selectLeft: true,
                selectRight: true,
                inclusiveLeft: false,
                inclusiveRight: false,
                readonly: true,
                replacedWith: a,
              })

              i++;
            }
            else if (t.type.includes('tag')) {
              let tag_name = t.string.match(tagNameRegExp)?.[1]
              let is_alone = tag_name && (t.string.endsWith('/>') || single_tags.includes(tag_name));
              let is_closed = !is_alone && t.string.startsWith('</');
              let a = document.createElement('span');

              if (!is_alone && !syntax_error) {
                open_tags += is_closed ? -1 : 1;
                if (!syntax_error && open_tags < 0) syntax_error = true;
              }

              if (syntax_error) a.className = 'cm-tag cm-error';
              else a.className = 'cm-tag';
              a.innerText = `  ${is_closed ? ti_c : ti_o}  `;
              a.setAttribute('data-placeholder', t.string);
              a.setAttribute('data-index', is_closed ? ti_c : ti_o);
              a.setAttribute('data-name', '');
              if (!is_alone) a.setAttribute('data-position', is_closed ? 'end' : 'start');

              this.cm.markText({line: line, ch: t.start}, {line: line, ch: t.end}, {
                className: "",
                atomic: true,
                selectLeft: true,
                selectRight: true,
                inclusiveLeft: false,
                inclusiveRight: false,
                replacedWith: a,
              })

              if (is_closed) {
                ti_c--;
              } else {
                ti_c = ti_o;
                ti_o++;
              }
            }
          })
        }

        this.syntax_error = syntax_error;
      }
    }
  },
  unmounted() {
    this.cm.toTextArea()
  },
  emits: ['update:modelValue', 'input', 'blur']
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/codemirror_placeholders.scss";
</style>