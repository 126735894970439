<template>
    <transition name="loading-transition" appear>
        <div ref="loading" class="loading_wrapper loading"
             :class="{'mobile': $store.getters.MOBILE, blur, fixed}"
        >
            <div v-if="circle" class="loading_wrapper__circle" :style="style" :class="{dictionary}">
<!--                <?xml version="1.0" encoding="utf-8"?>-->
                <svg xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     style="margin: auto; background: none; display: block; shape-rendering: auto; animation-play-state: running; animation-delay: 0s;"
                     width="100%" height="100%"
                     viewBox="0 0 100 100"
                     preserveAspectRatio="xMidYMid"
                >
                    <g transform="rotate(0 50 50)" style="animation-play-state: running; animation-delay: 0s;">
                        <rect x="42" y="2" rx="8" ry="8" width="16" height="16" fill="#48c68f"
                              style="animation-play-state: running; animation-delay: 0s;">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9s"
                                     repeatCount="indefinite"
                                     style="animation-play-state: running; animation-delay: 0s;"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(36 50 50)" style="animation-play-state: running; animation-delay: 0s;">
                        <rect x="42" y="2" rx="8" ry="8" width="16" height="16" fill="#48c68f"
                              style="animation-play-state: running; animation-delay: 0s;">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8s"
                                     repeatCount="indefinite"
                                     style="animation-play-state: running; animation-delay: 0s;"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(72 50 50)" style="animation-play-state: running; animation-delay: 0s;">
                        <rect x="42" y="2" rx="8" ry="8" width="16" height="16" fill="#48c68f"
                              style="animation-play-state: running; animation-delay: 0s;">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.7s"
                                     repeatCount="indefinite"
                                     style="animation-play-state: running; animation-delay: 0s;"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(108 50 50)" style="animation-play-state: running; animation-delay: 0s;">
                        <rect x="42" y="2" rx="8" ry="8" width="16" height="16" fill="#48c68f"
                              style="animation-play-state: running; animation-delay: 0s;">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6s"
                                     repeatCount="indefinite"
                                     style="animation-play-state: running; animation-delay: 0s;"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(144 50 50)" style="animation-play-state: running; animation-delay: 0s;">
                        <rect x="42" y="2" rx="8" ry="8" width="16" height="16" fill="#48c68f"
                              style="animation-play-state: running; animation-delay: 0s;">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s"
                                     repeatCount="indefinite"
                                     style="animation-play-state: running; animation-delay: 0s;"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(180 50 50)" style="animation-play-state: running; animation-delay: 0s;">
                        <rect x="42" y="2" rx="8" ry="8" width="16" height="16" fill="#48c68f"
                              style="animation-play-state: running; animation-delay: 0s;">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4s"
                                     repeatCount="indefinite"
                                     style="animation-play-state: running; animation-delay: 0s;"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(216 50 50)" style="animation-play-state: running; animation-delay: 0s;">
                        <rect x="42" y="2" rx="8" ry="8" width="16" height="16" fill="#48c68f"
                              style="animation-play-state: running; animation-delay: 0s;">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3s"
                                     repeatCount="indefinite"
                                     style="animation-play-state: running; animation-delay: 0s;"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(252 50 50)" style="animation-play-state: running; animation-delay: 0s;">
                        <rect x="42" y="2" rx="8" ry="8" width="16" height="16" fill="#48c68f"
                              style="animation-play-state: running; animation-delay: 0s;">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.2s"
                                     repeatCount="indefinite"
                                     style="animation-play-state: running; animation-delay: 0s;"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(288 50 50)" style="animation-play-state: running; animation-delay: 0s;">
                        <rect x="42" y="2" rx="8" ry="8" width="16" height="16" fill="#48c68f"
                              style="animation-play-state: running; animation-delay: 0s;">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.1s"
                                     repeatCount="indefinite"
                                     style="animation-play-state: running; animation-delay: 0s;"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(324 50 50)" style="animation-play-state: running; animation-delay: 0s;">
                        <rect x="42" y="2" rx="8" ry="8" width="16" height="16" fill="#48c68f"
                              style="animation-play-state: running; animation-delay: 0s;">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s"
                                     repeatCount="indefinite"
                                     style="animation-play-state: running; animation-delay: 0s;"></animate>
                        </rect>
                    </g>
                </svg>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "w_loading",
        props: {
            blur: {
                type: Boolean,
                default: false
            },
            fixed: {
                type: Boolean,
                default: false
            },
            circle: {
                type: Boolean,
                default: true
            },
            dictionary: {
                type: Boolean,
                default: false
            },
            width: {
                type: String,
                default: '4em'
            },
            color: {
                type: String,
                default: '#48C68F'
            },
        },
        computed: {
            style() {
                return {
                    width: this.width,
                    height: this.width,
                }
            }
        }
    }
</script>

<style lang="scss">
    .loading_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;

        &.blur {
            backdrop-filter: blur(4px);
        }

        &.fixed {
            padding-top: 150px;
            align-items: normal;
        }

        &.mobile {
            position: fixed;
            height: 100%;
            margin: 0;
        }

        &__circle {
            position: relative;
            margin: auto;

            &.dictionary {
                margin: 40vh auto auto;
                position: fixed;
                top: 0;
            }
        }
    }

    .loading-transition {
        &-enter-from, &-leave-to {
            opacity: 0;
        }

        &-enter-active, &-leave-active {
            transition: opacity 200ms ease;
        }
    }

    @media (max-width: 1440px) and (min-width: 981px) {
        .loading_wrapper {
            &.fixed {
                padding-top: 100px;
            }

            &__circle {
                transform: scale(.7);
            }
        }
    }
</style>
