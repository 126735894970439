import {createStore} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from "vuex-shared-mutations";
import SecureLS from "secure-ls";

import alerts from './modules/alerts';
import auth from './modules/auth';
import cache from './modules/cache';
import chat from './modules/chat';
import crew from './modules/crew';
import errors from './modules/errors';
import keys from './modules/keys';
import languages from './modules/languages';
import loadings from './modules/loadings';
import notifications from './modules/notifications';
import orders from './modules/orders';
import other from './modules/other';
import payments from './modules/payments';
import popups from './modules/popups';
import profile from './modules/profile';
import projects from './modules/projects';
import sections from './modules/sections';
import teams from './modules/teams';

import UNFULFILLED_ACTIONS from "../assets/js/unfulfilled_actions";

const ls = new SecureLS({isCompression: false});

export default createStore({
    modules: {
        alerts,
        auth,
        cache,
        chat,
        crew,
        errors,
        keys,
        languages,
        loadings,
        notifications,
        orders,
        other,
        payments,
        popups,
        profile,
        projects,
        sections,
        teams
    },
    plugins: [
        (store) => {
            store.subscribeAction({
                // before(action, state) {
                //     if (state.loadings.loading_refresh_token) UNFULFILLED_ACTIONS.set(action.type, action.payload)
                // },
                after(action) {
                    if (UNFULFILLED_ACTIONS.has(action.type)) UNFULFILLED_ACTIONS.delete(action.type)
                },
                error(action) {
                    UNFULFILLED_ACTIONS.set(action.type, action.payload)
                }
            })
        },
        createPersistedState({
            key: 'briefcase',
            paths: [
                'auth',
                'languages',
                'profile',
                'teams'
            ],
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
        createPersistedState({
            key: 'cache',
            paths: [
                'cache',
                'projects',
                'crew',
            ],
        }),
        createMutationsSharer({
            predicate: [
                "SAVE_TOKEN",
                "SAVE_TOKEN_TYPE",
                "SAVE_EXPIRES_IN",
                "SAVE_REFRESH_TOKEN",
                "SAVE_AUTHORIZATION_DATE",
                "SAVE_USER_LANG",
                "SAVE_USER",
                "CHANGE_SITE_THEME_PREFERS",
                "UPDATE_ORDERS_KEY",
                "CHANGE_ACTIVE_PAYMENT_ORDER",
                "SAVE_LIMIT_EXCEEDED_POPUP",
            ]
        })
    ]
})
